import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography, Tooltip } from '@mui/material';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { NavLink, useHistory } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import { gql, useApolloClient } from "@apollo/client";
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import { AuthContext } from './auth/AuthContext';
import { useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const GET_ALL_TEAM_IDS = gql`
  query GetAllTeamsIds($createdBy: String) {
    teams(where: {createdBy: {id: $createdBy}}) {
      id
      name
    }
  }
`;

const GET_TEAM = gql`
  query GetTeamFiltered($teamId: String) {
    teams(where: 
      {
        AND: [
          {id: $teamId},
        ]
      }) {
      id
      name
      top4Rate
      winRate
      membersConnection {
        edges {
          position
          node {
            id
            name
            imageSrc
            cost
          }
        }
      }
    }
  }
`;

const WhiteTextTypography = styled(Typography)({
  root: {
      color: "#FFFFFF",
      fontFamily: "Outfit"
  }
});


function WhiteCircularProgress(){  return ( <CircularProgress style={{'color': 'white'}}/>) }

function CompRow(props) {

  const history = useHistory();

  const pushToTeamBuilder = (team) => {
    history.push('/team-builder', { teamId: team.id, teamData: team.membersConnection });
  };

  return (
    <div>
    <Box sx={{ padding: '10px 20px', backgroundColor:'transparent'}}>
      <WhiteTextTypography sx={{fontFamily:"Outfit",color: 'white', fontSize:"20px"}} >{props.team.name}</WhiteTextTypography>
      {props.team.winRate ? (
        <WhiteTextTypography sx={{ fontFamily: "Outfit", color: 'white', fontSize: "15px", paddingBottom: '20px' }}>
          {"Win Rate: " + props.team.winRate + " | Top 4 Rate: " + props.team.top4Rate}
        </WhiteTextTypography>
      ) : (
        <div style={{ height: "42.5px" }}></div>
      )}
      <Box sx={{ maxWidth: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: "0 auto", flexWrap: "wrap" }}>
        {props.team.membersConnection.edges.map((member) => (
          <Box style={{ marginInline: "auto", display: "grid" }} key={`box-${props.team.id}-${member.node.id}-${Math.random()}`}>
            <Tooltip title={member.node.name} key={`tooltip${props.team.id}${member.node.id}${member.position}`} arrow>
              <img
                key={`${props.team.id}${member.node.id}${member.position}`}
                src={member.node.imageSrc}
                alt={member.node.name}
                style={{
                  width: "40px",
                  height: "40px",
                  margin: "2px",
                  border: `${
                    member.node.cost === 1
                      ? "2px solid grey"
                      : member.node.cost === 2
                      ? "2px solid green"
                      : member.node.cost === 3
                      ? "2px solid rgba(79, 163, 255, 0.8)"
                      : member.node.cost === 4
                      ? "2px solid purple"
                      : member.node.cost === 5
                      ? "2px solid gold"
                      : "2px solid black"
                  }`,
                }}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop:"10px" }}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={() => pushToTeamBuilder(props.team)}>
          Open in Team Builder
        </Button>
      </Box>
    </Box>
    <Divider style={{ backgroundColor: 'white', margin: '0 auto', width: '400px', marginTop: '10px', marginBlock: '10px' }} />
  </div>
  
  )
}

function Meta() {

  const user = useContext(AuthContext);
  const history = useHistory();

  const meta = {
    title: 'TFT Set 10 Tier List (Best Champions and Best Teams) - Teamfight Tactics - GraphTFT',
    description: 'Tier list of best team comps and best champions for TFT Set 10 Horizonbound',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'tft,meta,team comps,team builder, tft set 10,tft mid set, tier list,tft team comps,tft champion guide,tft team comp,tft planner,tft meta'
        }
    }
  }

  const [teamIdsMeta, setTeamIdsMeta] = useState(null);
  const [teamIdsUser, setTeamIdsUser] = useState(null);

  const client = useApolloClient();

  const mobileVersion = useMediaQuery(`(max-width:${1100}px)`);

  var compsHeight = 0
  if (!mobileVersion) {
    compsHeight = "calc(100dvh - 226px)"
  }
  else {
    compsHeight = "calc(100dvh - 206px)"
  }

  useEffect(() => {
    async function fetchTeamId() {
      const { data } = await client.query({
        query: GET_ALL_TEAM_IDS,
        variables: { createdBy: "meta" },
      });
      setTeamIdsMeta(data);
    }
    fetchTeamId();
  }, [client]);

  useEffect(() => {
    let isMounted = true;
    let loadedTeams = [];
    async function fetchTeams() {
      if (teamIdsMeta) {
        const pageSize = 10; // Number of teams to fetch per page
        const totalPages = Math.ceil(teamIdsMeta.teams.length / pageSize);
  
        for (let page = 1; page <= totalPages; page++) {
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const teamsToFetch = teamIdsMeta.teams.slice(startIndex, endIndex);
  
          const teamQueries = teamsToFetch.map((team) =>
            client.query({
              query: GET_TEAM,
              variables: { teamId: team.id },
              fetchPolicy: 'cache-first'
            })
          );
  
          const teamResults = await Promise.all(teamQueries);
          const teams = teamResults.flatMap((result) => result.data.teams);
          loadedTeams = loadedTeams.concat(teams);
  
          if (isMounted) {
            setAllTeamsMeta(loadedTeams);
          }
        }
      }
    }

    fetchTeams();

  }, [teamIdsMeta, client]);

  
  useEffect(() => {
    if (!user) {
      return;
    }
    async function fetchTeamId() {
      const { data } = await client.query({
        query: GET_ALL_TEAM_IDS,
        variables: { createdBy: user.email },
      });
      setTeamIdsUser(data);
    }
  
    fetchTeamId();
  }, [client, user]);

  const [allTeamsMeta, setAllTeamsMeta] = useState([]);
  const [allTeamsUser, setAllTeamsUser] = useState([]);
  const [teamCompSource, setTeamCompSouce] = useState("meta");

  const handleSourceChange = (event) => {
    setTeamCompSouce(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    let loadedTeams = [];
  
    async function fetchTeams() {
      if (teamIdsUser) {
        const pageSize = 10; // Number of teams to fetch per page
        const totalPages = Math.ceil(teamIdsUser.teams.length / pageSize);
  
        for (let page = 1; page <= totalPages; page++) {
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const teamsToFetch = teamIdsUser.teams.slice(startIndex, endIndex);
  
          const teamQueries = teamsToFetch.map((team) =>
            client.query({
              query: GET_TEAM,
              variables: { teamId: team.id },
              fetchPolicy: 'cache-first'
            })
          );
  
          const teamResults = await Promise.all(teamQueries);
          const teams = teamResults.flatMap((result) => result.data.teams);
          loadedTeams = loadedTeams.concat(teams);
  
          if (isMounted) {
            setAllTeamsUser(loadedTeams);
          }
        }
      }
    }
  
    fetchTeams();
  
  }, [teamIdsUser, client]);

  const [loadedMeta, setLoadedMeta] = useState(false);
  const [loadedUser, setLoadedUser] = useState(false);

  useEffect(() => {
    if (allTeamsMeta.length !== 0) {
      setLoadedMeta(true);
    }
  }, [allTeamsMeta]);

  useEffect(() => {
    if (allTeamsUser) {
      setLoadedUser(true);
    }
  }, [allTeamsUser]);

  if (!mobileVersion) {
    return (

    <Grid container spacing={2}>
      <DocumentMeta {...meta} />
      {/* First Column */}
      <Grid item xs={6}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Box sx={{backgroundColor:"rgba(255, 255, 255, 0.05)", width: "500px",paddingBlock:'20px', marginBlock:'10px', marginInline:'10px', paddingLeft:"20px", paddingRight:"20px", borderRadius: '5px'}}>
            <Box sx={{marginBottom:"20px"}}>
              <Box>
                <WhiteTextTypography variant="h4" sx={{fontFamily:"Outfit",color: 'white', marginLeft:'20px'}}>Meta TFT</WhiteTextTypography>
              </Box>
              <Box>
                <WhiteTextTypography sx={{fontFamily:"Outfit",color: 'white', fontSize: '15px', marginLeft:'20px'}}>Meta TFT Team Comps Tier List - Set 10 Remix Rumble. The best TFT team comps for set 10, used in the team builder.</WhiteTextTypography>
              </Box>
            </Box>
          {(!loadedMeta) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}>
              <WhiteCircularProgress sx={{ width: '100px', height: '100px' }} />
            </Box>
          )}
          <div style={{ maxHeight: compsHeight, overflowY: 'auto', width: '500px'  }}>
            {(loadedMeta && allTeamsMeta) && (
              allTeamsMeta.map((team, index) => (
                <CompRow team={team} key={team.id} />
              ))
            )}
          </div>
          </Box>
        </Box>
      </Grid>

      {/* Second Column */}
      <Grid item xs={6}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Box sx={{backgroundColor:"rgba(255, 255, 255, 0.05)", width: "500px",paddingBlock:'20px', marginBlock:'10px', marginInline:'10px', paddingLeft:"20px", paddingRight:"20px", borderRadius: '5px'}}>
            <Box sx={{marginBottom:"20px"}}>
              <Box>
                <WhiteTextTypography variant="h4" sx={{fontFamily:"Outfit",color: 'white', marginLeft:'20px'}}>Your Saved Teams</WhiteTextTypography>
              </Box>
              <Box>
                <WhiteTextTypography sx={{fontFamily:"Outfit",color: 'white', fontSize: '15px', marginLeft:'20px'}}>Teams you saved from the team builder!</WhiteTextTypography>
              </Box>
          </Box>
          {(!user) ? (
          <div style={{ textAlign: 'center', padding: '10px', color: 'white' }}>
            <InfoIcon style={{ paddingTop: '10px' }} />
            <Typography align='center' style={{ width: '12vw' }}>
              Create an account to save your own teams!
            </Typography>
            <WhiteTextTypography variant="body1" align="center" mt={2}>
              <NavLink to="/login" style={{ color: 'white' }}>
                Log In
              </NavLink>
              {" or "}
              <NavLink to="/signup" style={{ color: 'white' }}>
                Sign Up
              </NavLink>
            </WhiteTextTypography>
          </div>
          ) : (
            <React.Fragment>
              {(!loadedUser) && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}>
                  <WhiteCircularProgress sx={{ width: '100px', height: '100px' }} />
                </Box>
              )}
              <div style={{ maxHeight: compsHeight, overflowY: 'auto', width:'500px' }}>
                {(loadedUser && allTeamsUser) && (
                  allTeamsUser.map((team, index) => (
                    <CompRow team={team} key={team.id} />
                  ))
                )}
              </div>
            </React.Fragment>
          )}
          </Box>
        </Box>
      </Grid>
    </Grid>        
);
} else {
  return (
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    width: '500px',
    paddingBlock: '20px',
    marginBlock: '10px',
    marginInline: 'auto', // Center horizontally
    borderRadius: '5px',
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <WhiteTextTypography variant="h4" sx={{ fontFamily: "Outfit", color: 'white', marginLeft: '20px' }}>
      Source:
    </WhiteTextTypography>
    <Select
      value={teamCompSource}
      onChange={handleSourceChange}
      style={{
        backgroundColor: "white",
        color: "black",
        maxHeight: 36,
        width: 100,
        marginLeft: '20px', // Add spacing between "Source" and the Select
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: "white",
            color: "black",
          },
        },
        MenuListProps: {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        },
      }}
    >
      <MenuItem value="meta">META</MenuItem>
      <MenuItem disabled={!user} value="user">USER</MenuItem>
    </Select>
  </Box>
  
  {/* Conditionally render content based on teamCompSource */}
  {teamCompSource === "meta" ? (
    <React.Fragment>
      {(!loadedMeta) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}>
          <WhiteCircularProgress sx={{ width: '100px', height: '100px' }} />
        </Box>
      )}
      <div style={{ maxHeight: compsHeight, overflowY: 'auto', width: '500px',  }}>
        {(loadedMeta && allTeamsMeta) && (
          allTeamsMeta.map((team, index) => (
            <CompRow team={team} key={team.id} />
          ))
        )}
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {(!loadedUser) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}>
          <WhiteCircularProgress sx={{ width: '100px', height: '100px' }} />
        </Box>
      )}
      <div style={{ maxHeight: compsHeight, overflowY: 'auto', width:'500px' }}>
        {(loadedUser && allTeamsUser) && (
          allTeamsUser.map((team, index) => (
            <CompRow team={team} key={team.id} />
          ))
        )}
      </div>
    </React.Fragment>
  )}
</Box>


  );
}

} 

export default Meta;
