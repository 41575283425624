import * as React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Exploration from './components/Exploration.js'
import Comps from './components/Comps.js'
import Welcome from './components/Welcome.js'
import ResponsiveAppBar from './components/ResponsiveAppBar.js'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './assets/css/fonts.css';
import SignUp from './components/auth/SignUp.js'
import LogIn from './components/auth/LogIn.js'
import Account from './components/auth/Account.js'
import { AuthProvider } from './components/auth/AuthContext';
import ForgotPassword from './components/auth/ForgotPassword.js'
import Action from './components/auth/Action.js'

const theme = createTheme({
  palette: {
    primary: {
      main: '#e5e5e5',
    },
    secondary: {
      main: '#2E3B55',
    },
  },
  typography: {
    fontFamily: "'Outfit'",
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0)', // fully transparent
      },
    },
  }
});

document.body.style = 'background: #121212; margin:0; height:100%';

export default function App(){

    return (
      <AuthProvider>
        <ThemeProvider theme={theme}>        
          <Router>
              <div style={{ 'display': 'flex', 'flexDirection': 'bottom', "margin":"0" }}>
                  <main style={{flexGrow: 1, }}>
                      <ResponsiveAppBar />
                          <Switch>
                              <Route path='/' component={Welcome} exact />
                              <Route exact path='/team-builder' component={() => <Exploration />} />
                              <Route exact path='/team-comps' component={() => <Comps />} />
                              <Route exact path='/signup' component={() => <SignUp />} />
                              <Route exact path='/login' component={() => <LogIn />} />
                              <Route exact path='/account' component={() => <Account />} />
                              <Route exact path='/forgot-password' component={() => <ForgotPassword />} />
                              <Route exact path='/action' component={() => <Action />} />
                          </Switch>
                  </main>
              </div>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    )
}