import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/system';  // `makeStyles` is replaced with `styled` or `sx`
import Typography from '@mui/material/Typography';

const StyledStarIcon = styled(StarIcon)({
  color: "white",
  scale: "0.7",
  position: 'absolute',
  top: '8px', // Adjust the values here to position the icon correctly
  zIndex: 1, // Set a high z-index to ensure the icon is displayed over the image
  opacity: 0.8,
});

function StarMenu(props) {
  const [star1, setStar1] = useState(props.champion.star === 1);
  const [star2, setStar2] = useState(props.champion.star === 2);
  const [star3, setStar3] = useState(props.champion.star === 3);
  const [from, setFrom] = useState()
  const [menuOpen, setMenuOpen] = useState(false);
  const parentRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    // setMenuPosition({ left: `${event.clientX}px`, top: `${event.clientY}px` });
    if (props.champion.id != null) {
      setMenuOpen(true);
      if (star1) {
        setFrom(1)
      } else if (star2) {
        setFrom(2)
      } else {
        setFrom(3)
      }
    }
  };

  useEffect(() => {
    setStar1(props.champion.star === 1)
    setStar2(props.champion.star === 2)
    setStar3(props.champion.star === 3)
}, [props.champion]); 

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <div onContextMenu={handleContextMenu} ref={parentRef}>
      {props.children}
      <Menu
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        anchorEl={parentRef.current}
        anchorReference="anchorEl"
      >
        <MenuItem onClick={() => { props.handleStar1(from, props.champion, props.position); handleClose(); setStar1(true); setStar2(false); setStar3(false)}}>
          <StyledStarIcon />1 Star
        </MenuItem>
        <MenuItem onClick={() => { props.handleStar2(from, props.champion, props.position); handleClose(); setStar1(false); setStar2(true); setStar3(false)}}>
          <StyledStarIcon />2 Star
        </MenuItem>
        <MenuItem onClick={() => { props.handleStar3(from, props.champion, props.position); handleClose(); setStar1(false); setStar2(false); setStar3(true)}}>
          <StyledStarIcon />3 Star
        </MenuItem>
      </Menu>
      {!props.matches && props.champion.id != null && star1 &&(
        <div>
        <StyledStarIcon style={{left:"14px"}}/>
        </div>
      )}
      {!props.matches && props.champion.id != null && star2 &&(
        <div>
        <StyledStarIcon style={{left:"5px"}}/>
        <StyledStarIcon style={{left:"21px"}}/>
        </div>

      )}
      {!props.matches && props.champion.id != null && star3 &&(
        <div>
        <StyledStarIcon style={{left:"-1px"}} />
        <StyledStarIcon style={{left:"14px"}} />
        <StyledStarIcon style={{left:"29px"}} />
        </div>
      )}
      {!props.matches && props.champion.id != null && (
        <Typography style={{color: "white", fontSize: 8,top: '0px', zIndex: 1,flexGrow:1, textAlign:'center', paddingTop: "25px", position: "relative"}}>{props.champion.name}</Typography>
          )
      }
    </div>
  );
}

export default StarMenu;
