import { initializeApp } from "firebase/app";
import 'firebase/firestore'; // Import Firestore if you only need Firestore features.
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCAxPL0qCaGYt_HkzhsIJA85VA5-8z4a20",
    authDomain: "graphtft-854d3.firebaseapp.com",
    projectId: "graphtft-854d3",
    storageBucket: "graphtft-854d3.appspot.com",
    messagingSenderId: "956727374313",
    appId: "1:956727374313:web:bd67eadf3b5c066f9a9b78",
    measurementId: "G-X7G5XPDG1L"
  };

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
export { auth, db } ;