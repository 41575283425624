import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import WhiteTextTypography from '../utils/WhiteTextTypography';

const SelectedChampion = ({ characterData, removeFromClick, addItemCombined }) => {
  return (
    <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '20px' }} >
            <WhiteTextTypography variant="h5" component="div" sx={{borderBottom: '4px solid #ffffff'}}>
                {characterData.champions[0].name}
            </WhiteTextTypography>
            <img src={characterData.champions[0].imageSrc}   alt={characterData.champions[0].name} style={{width: '50px', height: '50px',                                 border: `${characterData.champions[0].cost === 1 ? "2px solid grey" : characterData.champions[0].cost === 2 ? "2px solid green" : characterData.champions[0].cost === 3 ? "2px solid rgba(79, 163, 255, 0.8)" : characterData.champions[0].cost === 4 ? "2px solid purple" :  characterData.champions[0].cost === 5 ? "2px solid gold" : "2px solid black"}`}} />
        </Box>
      <TableContainer component={Paper} sx={{backgroundColor:"#121212", borderRadius: "2px"}} elevation={0}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table" size="small">
          <TableBody>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <WhiteTextTypography style={{fontSize:"0.875rem"}}>
                            Cost
                        </WhiteTextTypography>
                    </TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <WhiteTextTypography style={{fontSize:"0.875rem"}}>
                            {characterData.champions[0].cost}
                        </WhiteTextTypography>
                        </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <WhiteTextTypography style={{fontSize:"0.875rem"}}>
                        Synergies
                        </WhiteTextTypography>
                        </TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <WhiteTextTypography style={{fontSize:"0.875rem"}}>
                            {characterData.champions[0].synergies.map(sy => sy.name).join(", ")}
                        </WhiteTextTypography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Recommended Items</TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <Box sx={{ width: "90%",m: 2 }}  >
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                {characterData.champions[0].recommendedItems.filter((item, index) => {
                                    return index === characterData.champions[0].recommendedItems.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item));
                                })
                                
                                .map(item => (
                                    <Grid key={item.id} item>                                        
                                        <Box 
                                            sx={{
                                                // backgroundColor: 'primary.dark',
                                                '&:hover': {
                                                // backgroundColor: 'primary.main',
                                                opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                        >     
                                            <Tooltip title={item.name} arrow>
                                                <img src = {`${item.imageSrc}`} alt= {`${item.name}`} onClick={()=>addItemCombined(item.id)} width="40" height="40"/>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    ))
                                }                
                            </Grid>            
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>

        </Table>
      </TableContainer>
      <Box
        sx={{
          marginTop: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button 
          variant="contained"
          onClick={() => removeFromClick(characterData.champions[0])}
          sx={{ marginLeft: "10px", backgroundColor:"white" }}
        >
          Remove From Team
        </Button>                     
      </Box>
    </Box>
  );
}

export default SelectedChampion;
