import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import WhiteTextTypography from '../utils/WhiteTextTypography';

const SelectedItemBasic = ({itemBasicData, addItemCombined, expandBuilds}) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '20px' }} >
            <WhiteTextTypography variant="h5" component="div" sx={{borderBottom: '4px solid #ffffff'}}>
                {itemBasicData.itemBasics[0].name}
            </WhiteTextTypography>
            <img src={itemBasicData.itemBasics[0].imageSrc}   alt={itemBasicData.itemBasics[0].name} style={{width: '50px', height: '50px'}} />
        </Box>
        <TableContainer component={Paper} sx={{backgroundColor:"#121212", borderRadius: "2px"}} elevation={0}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Bonus</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{itemBasicData.itemBasics[0].bonus[0].substring(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Tier</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{itemBasicData.itemBasics[0].tier}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Builds</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                                <Box sx={{ m: 2 }}  >
                                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                        {itemBasicData.itemBasics[0].builds.map( item => (
                                    <Grid key={item.name} item>                                        
                                        <Box 
                                            sx={{
                                                '&:hover': {
                                                opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                        >     
                                            <Tooltip title={item.name} arrow>
                                            <img src = {`${item.imageSrc}`} alt= {`${item.name}`} onClick={()=>addItemCombined(`${item.id}`)} width="40" height="40"/>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    ))}                
                                    </Grid>            
                                    </Box>   
                            </TableCell>
                        </TableRow>                                                                                                                             
                    </TableBody>
                </Table>
            </TableContainer>
            <div
                style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={expandBuilds}
                    sx={{backgroundColor:"white"}}
                >
                    Expand Builds
                </Button>
            </div>
        </Box>
    )
}

export default SelectedItemBasic;
