import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from 'firebase/auth';
import { Box, Typography, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import WhiteTextTypography from '../utils/WhiteTextTypography';
import WhiteTextField from '../utils/WhiteTextField';

const Action = () => {
  const history = useHistory();
  const auth = getAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [displayPasswordReset, setDisplayPasswordReset] = useState(false);
  const [displayEmailVerification, setDisplayEmailVerification] = useState(true);

  const [errors, setErrors] = useState([]);

  const matches = useMediaQuery(`(max-width:${600}px)`);
  var explorationHeight = 0;
  if (!matches) {
    explorationHeight = "calc(100vh - 112px)";
  } else {
    explorationHeight = "calc(100vh - 120px)";
  }

  const mode = urlParams.get('mode');
  const actionCode = urlParams.get('oobCode');
  const continueUrl = urlParams.get('continueUrl');

  useEffect(() => {
    switch (mode) {
      case 'resetPassword':
        handleResetPassword();
        break;
      case 'verifyEmail':
        handleVerifyEmail();
        break;
      // Add other modes here
      default:
        break;
    }
  }, [mode, actionCode, continueUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetPassword = async () => {
    try {
      await verifyPasswordResetCode(auth, actionCode);
      setErrors([]);
      setDisplayPasswordReset(true);
    } catch (error) {
      // Handle error, perhaps redirect to an error page
      // You can enhance the error handling by providing more specific error messages based on the error codes.
      if (error.code === "auth/invalid-action-code") {
        setErrors(["Invalid or expired reset password code"]);
      } else {
        setErrors(["Something went wrong"]);
      }
    }
  };

  const handleVerifyEmail = async () => {
    try {
      await applyActionCode(auth, actionCode);
      setErrors([]);
      setDisplayEmailVerification(true);
      // Redirect to login after verification
      // history.push('/login');
    } catch (error) {
      console.log(error)
      // Handle error, such as invalid or expired code.
      // You can enhance the error handling by providing more specific error messages based on the error codes.
      if (error.code === "auth/invalid-action-code") {
        setErrors(["Invalid or expired verification code"]);
      } else {
        setErrors(["Something went wrong"]);
      }
    }
  };

  const saveNewPassword = async (event) => {
    event.preventDefault();
    let errorList = [];
    if (newPassword !== confirmPassword) {
      errorList.push("Passwords do not match");
    }
    if (errorList.length > 0) {
      setErrors(errorList);
      return;
    }
  
    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      // Redirect to login after password reset
      history.push('/login');
    } catch (error) {
      // Handle error, such as weak password
      if (error.code === "auth/weak-password") {
        setErrors(["Password must be atleast six characters"]);
      } else {
        setErrors(["Something went wrong"]);
      }
    }
  };

  return (
    <>
      {displayPasswordReset ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={explorationHeight}
          width="100%"
        >
          {/* Password reset box */}
          <Box>
            <WhiteTextTypography variant="h4" align="center" gutterBottom mb={2}>
              Reset Password
            </WhiteTextTypography>
            <Box maxWidth="400px">
              <WhiteTextTypography align="center" gutterBottom mb={3}>
                Please enter your new password.
              </WhiteTextTypography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" width="400px">
              <Box mb={2} width="300px">
                <WhiteTextField
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  placeholder="Enter new password"
                  inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white',
                      '&.Mui-focused': {
                        color: 'white',
                      },
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Box>
              <Box mb={2} width="300px">
                <WhiteTextField
                  type="password"
                  label="Confirm password"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  placeholder="Confirm Password"
                  inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white', // set the initial color of the label
                      '&.Mui-focused': {
                        color: 'white', // set the color of the label when the TextField is focused
                      },
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              {errors.length > 0 && (
                errors.map((error, index) => (
                  <Box key={index} mb={2} width="300px">
                    <Typography variant="body1" align="center" color="error">
                      {error}
                    </Typography>
                  </Box>
                ))
              )}

              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#3f51b5', color: '#fff' }}
                onClick={saveNewPassword}
              >
                Reset password
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}

      {displayEmailVerification ? (
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight={explorationHeight}
          >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <WhiteTextTypography variant="h4" align="center" gutterBottom mb={2}>
                  Account Verified!
              </WhiteTextTypography>
              <Box display="flex" justifyContent="center" width="100%">
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => { 
                          window.location.href = '/login';
                      }}
                  >
                      Log In
                  </Button>
              </Box>
          </Box>  
          </Box>
      ) : null}
    </>
  );
};

export default Action;