import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import WhiteTextTypography from '../utils/WhiteTextTypography';
const SelectedItemCombined = ({itemCombinedData, addItemBasic, setNodeToAddFromClick, expandBuiltBy, expandRecommendedFor}) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '20px' }} >
                <WhiteTextTypography variant="h5" component="div" sx={{borderBottom: '4px solid #ffffff'}}>
                    {itemCombinedData.itemCombineds[0].name}
                </WhiteTextTypography>
                <img src={itemCombinedData.itemCombineds[0].imageSrc}   alt={itemCombinedData.itemCombineds[0].name} style={{width: '50px', height: '50px'}} />
            </Box>
            <TableContainer component={Paper} sx={{backgroundColor:"#121212", borderRadius: "2px"}} elevation={0}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Bonus</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{itemCombinedData.itemCombineds[0].bonus[0].substring(1)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Tier</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{itemCombinedData.itemCombineds[0].tier}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Built By</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                                <Box sx={{ m: 2 }}  >
                                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                        { (itemCombinedData.itemCombineds[0].builtBy.length === 1) ? ( itemCombinedData.itemCombineds[0].builtBy.map( item => (
                                        <div style={{display: "flex"}}>
                                            <Grid key={item.id} item>                                        
                                                <Box 
                                                    sx={{
                                                        // backgroundColor: 'primary.dark',
                                                        '&:hover': {
                                                        // backgroundColor: 'primary.main',
                                                        opacity: [0.9, 0.8, 0.7],
                                                        },
                                                    }}
                                                >     
                                                    <Tooltip title={item.name} arrow>
                                                        <img src = {`${item.imageSrc}`} alt= {`${item.name}`} onClick={()=>addItemBasic(item.id)} width="40" height="40"/>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                            <Grid key={"duplicate"} item>                                        
                                                <Box 
                                                    sx={{
                                                        // backgroundColor: 'primary.dark',
                                                        '&:hover': {
                                                        // backgroundColor: 'primary.main',
                                                        opacity: [0.9, 0.8, 0.7],
                                                        },
                                                        paddingLeft: "10px"
                                                    }}
                                                >     
                                                    <Tooltip title={item.name} arrow>
                                                        <img src = {`${item.imageSrc}`} alt= {`${item.name}`} onClick={()=>addItemBasic(item.id)} width="40" height="40"/>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                        </div>
                                        )) ): ( itemCombinedData.itemCombineds[0].builtBy.map( item => (
                                        <Grid key={item.id} item>                                        
                                            <Box 
                                                sx={{
                                                    // backgroundColor: 'primary.dark',
                                                    '&:hover': {
                                                    // backgroundColor: 'primary.main',
                                                    opacity: [0.9, 0.8, 0.7],
                                                    },
                                                }}
                                            >     
                                                <Tooltip title={item.name} arrow>
                                                    <img src = {`${item.imageSrc}`} alt= {`${item.name}`} onClick={()=>addItemBasic(item.id)} width="40" height="40"/>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        )))}                
                                    </Grid>            
                                </Box>   
                            </TableCell>
                        </TableRow> 
                        <TableRow>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Recommended For</TableCell>
                            <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                                <Box sx={{ m: 2 }}  >
                                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                        {itemCombinedData.itemCombineds[0].recommendedFor.filter((item, index) => {
                                                return index === itemCombinedData.itemCombineds[0].recommendedFor.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item));
                                            }).slice().sort((a,b) => {
                                                return a.cost - b.cost;
                                            }).map( champion => (
                                        <Grid key={champion.id} item>                                        
                                            <Box 
                                                sx={{
                                                    '&:hover': {
                                                    opacity: [0.9, 0.8, 0.7],
                                                    },
                                                }}
                                            >     
                                            <Tooltip title={champion.name} arrow>
                                            <img src = {`${champion.imageSrc}`} alt= {`${champion.name}`} onClick={()=>setNodeToAddFromClick(champion)} style={{border: `${champion.cost === 1 ? "3px solid grey" : champion.cost === 2 ? "3px solid green" : champion.cost === 3 ? "3px solid rgba(79, 163, 255, 0.8)" : champion.cost === 4 ? "3px solid purple" :  champion.cost === 5 ? "3px solid gold" : "3px solid black"}` }} width="40" height="40"/>
                                            </Tooltip>
                                            </Box>
                                        </Grid>
                                    ))}                
                                    </Grid>            
                                </Box>   
                            </TableCell>
                        </TableRow>                                                                                                                                                                     
                    </TableBody>
                </Table>
            </TableContainer>
            <div
    style={{
        marginBlock: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}
>
    <Button
        variant="contained"
        color="primary"
        onClick={expandBuiltBy}
        style={{ marginRight: "10px", transition: "background-color 0.3s ease", backgroundColor:"white" }}
        onMouseOver={(e) => { e.target.style.backgroundColor = '#3f51b5'; }}
        onMouseOut={(e) => { e.target.style.backgroundColor = ''; }}
    >
        Expand Items
    </Button>
    <Button
        variant="contained"
        color="primary"
        onClick={expandRecommendedFor}
        style={{ marginLeft: "10px", transition: "background-color 0.3s ease" }}
        onMouseOver={(e) => { e.target.style.backgroundColor = '#3f51b5'; }}
        onMouseOut={(e) => { e.target.style.backgroundColor = ''; }}
    >
        Expand Champs
    </Button>
</div>

        </Box>
    )
}

export default SelectedItemCombined;
