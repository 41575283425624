import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import WhiteTextTypography from '../utils/WhiteTextTypography';

const SelectedSynergy = ({ synergyData, expandSynergies, setNodeToAddFromClick}) => {
  return (
    <Box style={{autoflowY:"auto"}} >   
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '20px' }} >
            <WhiteTextTypography variant="h5" component="div" sx={{borderBottom: '4px solid #ffffff'}}>
                {synergyData.synergies[0].name}
            </WhiteTextTypography>
            <img src={synergyData.synergies[0].imageSrc}   alt={synergyData.synergies[0].name} style={{width: '50px', height: '50px'}} />
        </Box>
    <TableContainer component={Paper} sx={{backgroundColor:"#121212", borderRadius: "2px"}} elevation={0}>
        <Table  sx={{ minWidth: 300 }} aria-label="simple table" size="small">
            <TableBody>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Description</TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{synergyData.synergies[0].description}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Bonus</TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{synergyData.synergies[0].bonus.join(", ").substring(1)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>Champions</TableCell>
                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>
                        <Box sx={{ width: "90%",m: 2 }}  >
                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                {synergyData.synergies[0].champions.slice().sort((a,b) => {
                                    return a.cost - b.cost;
                                }).map(champion => (
                            <Grid key={champion.id} item>                                        
                                <Box 
                                    sx={{
                                        '&:hover': {
                                        opacity: [0.9, 0.8, 0.7],
                                        },
                                    }}
                                >     
                                    <Tooltip title={champion.name} arrow>
                                    <img src = {`${champion.imageSrc}`} alt= {`${champion.name}`} onClick={()=>setNodeToAddFromClick(champion)} style={{border: `${champion.cost === 1 ? "3px solid grey" : champion.cost === 2 ? "3px solid green" : champion.cost === 3 ? "3px solid rgba(79, 163, 255, 0.8)" : champion.cost === 4 ? "3px solid purple" :  champion.cost === 5 ? "3px solid gold" : "3px solid black"}` }}  width="40" height="40"/>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            ))}                
                            </Grid>            
                        </Box>  
                    </TableCell>
                </TableRow>                                                                                                 
            </TableBody>
        </Table>
    </TableContainer>
    <div
        style={{
            marginBlock: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <Button
            variant="contained"
            color="primary"
            onClick={expandSynergies}
            sx={{backgroundColor:"white"}}
        >
            Expand Champs
        </Button>
    </div>
</Box>
  );
}

export default SelectedSynergy;
