import React, { lazy, Suspense, useEffect, useState, useRef, useContext } from 'react';
import { gql, useLazyQuery, useApolloClient, useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip, Typography } from "@mui/material";
import { Grid, Drawer } from "@mui/material";
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import BuildIcon from '@mui/icons-material/Build';
import HexagonIcon from '@mui/icons-material/Hexagon';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DocumentMeta from 'react-document-meta';
import Switch from '@mui/material/Switch';
import StarMenu from './StarMenu';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import copy from 'copy-to-clipboard';
import { numberChampionMap, letterNumberMap } from './ChampionMap';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import Backdrop from '@mui/material/Backdrop';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import SelectedChampion from './selected-components/SelectedChampion.js'
import SelectedSynergy from './selected-components/SelectedSynergy'
import WhiteTextTypography from './utils/WhiteTextTypography';
import WhiteTextField from './utils/WhiteTextField';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from "@mui/icons-material/Favorite";
import Select from '@mui/material/Select';
import { AuthContext } from './auth/AuthContext';
import Link from '@mui/material/Link';
import SelectedItemCombined from './selected-components/SelectedItemCombined';
import SelectedItemBasic from './selected-components/SelectedItemBasic';

import eight_bit_grey from  '../assets/synergy-svg/grey/24_8_bit.svg'
import eight_bit_bronze from  '../assets/synergy-svg/bronze/24_8_bit.svg'
import eight_bit_silver from  '../assets/synergy-svg/silver/24_8_bit.svg'
import eight_bit_gold from  '../assets/synergy-svg/gold/24_8_bit.svg'
import eight_bit_green from  '../assets/synergy-svg/green/24_8_bit.svg'
import breakout_grey from  '../assets/synergy-svg/grey/24_breakout.svg'
import breakout_bronze from  '../assets/synergy-svg/bronze/24_breakout.svg'
import breakout_silver from  '../assets/synergy-svg/silver/24_breakout.svg'
import breakout_gold from  '../assets/synergy-svg/gold/24_breakout.svg'
import breakout_green from  '../assets/synergy-svg/green/24_breakout.svg'
import country_grey from  '../assets/synergy-svg/grey/24_country.svg'
import country_bronze from  '../assets/synergy-svg/bronze/24_country.svg'
import country_silver from  '../assets/synergy-svg/silver/24_country.svg'
import country_gold from  '../assets/synergy-svg/gold/24_country.svg'
import country_green from  '../assets/synergy-svg/green/24_country.svg'
import disco_grey from  '../assets/synergy-svg/grey/24_disco.svg'
import disco_bronze from  '../assets/synergy-svg/bronze/24_disco.svg'
import disco_silver from  '../assets/synergy-svg/silver/24_disco.svg'
import disco_gold from  '../assets/synergy-svg/gold/24_disco.svg'
import disco_green from  '../assets/synergy-svg/green/24_disco.svg'
import edm_grey from  '../assets/synergy-svg/grey/24_edm.svg'
import edm_bronze from  '../assets/synergy-svg/bronze/24_edm.svg'
import edm_silver from  '../assets/synergy-svg/silver/24_edm.svg'
import edm_gold from  '../assets/synergy-svg/gold/24_edm.svg'
import edm_green from  '../assets/synergy-svg/green/24_edm.svg'
import emo_grey from  '../assets/synergy-svg/grey/24_emo.svg'
import emo_bronze from  '../assets/synergy-svg/bronze/24_emo.svg'
import emo_silver from  '../assets/synergy-svg/silver/24_emo.svg'
import emo_gold from  '../assets/synergy-svg/gold/24_emo.svg'
import emo_green from  '../assets/synergy-svg/green/24_emo.svg'
import heartsteel_grey from  '../assets/synergy-svg/grey/24_heartsteel.svg'
import heartsteel_bronze from  '../assets/synergy-svg/bronze/24_heartsteel.svg'
import heartsteel_silver from  '../assets/synergy-svg/silver/24_heartsteel.svg'
import heartsteel_gold from  '../assets/synergy-svg/gold/24_heartsteel.svg'
import heartsteel_green from  '../assets/synergy-svg/green/24_heartsteel.svg'
import hyperpop_grey from  '../assets/synergy-svg/grey/24_hyperpop.svg'
import hyperpop_bronze from  '../assets/synergy-svg/bronze/24_hyperpop.svg'
import hyperpop_silver from  '../assets/synergy-svg/silver/24_hyperpop.svg'
import hyperpop_gold from  '../assets/synergy-svg/gold/24_hyperpop.svg'
import hyperpop_green from  '../assets/synergy-svg/green/24_hyperpop.svg'
import illbeats_grey from  '../assets/synergy-svg/grey/24_illbeats.svg'
import illbeats_bronze from  '../assets/synergy-svg/bronze/24_illbeats.svg'
import illbeats_silver from  '../assets/synergy-svg/silver/24_illbeats.svg'
import illbeats_gold from  '../assets/synergy-svg/gold/24_illbeats.svg'
import illbeats_green from  '../assets/synergy-svg/green/24_illbeats.svg'
import jazz_grey from  '../assets/synergy-svg/grey/24_jazz.svg'
import jazz_bronze from  '../assets/synergy-svg/bronze/24_jazz.svg'
import jazz_silver from  '../assets/synergy-svg/silver/24_jazz.svg'
import jazz_gold from  '../assets/synergy-svg/gold/24_jazz.svg'
import jazz_green from  '../assets/synergy-svg/green/24_jazz.svg'
import kda_grey from  '../assets/synergy-svg/grey/24_kda.svg'
import kda_bronze from  '../assets/synergy-svg/bronze/24_kda.svg'
import kda_silver from  '../assets/synergy-svg/silver/24_kda.svg'
import kda_gold from  '../assets/synergy-svg/gold/24_kda.svg'
import kda_green from  '../assets/synergy-svg/green/24_kda.svg'
import maestro_grey from  '../assets/synergy-svg/grey/24_maestro.svg'
import maestro_bronze from  '../assets/synergy-svg/bronze/24_maestro.svg'
import maestro_silver from  '../assets/synergy-svg/silver/24_maestro.svg'
import maestro_gold from  '../assets/synergy-svg/gold/24_maestro.svg'
import maestro_green from  '../assets/synergy-svg/green/24_maestro.svg'
import mixmaster_grey from  '../assets/synergy-svg/grey/24_mixmaster.svg'
import mixmaster_bronze from  '../assets/synergy-svg/bronze/24_mixmaster.svg'
import mixmaster_silver from  '../assets/synergy-svg/silver/24_mixmaster.svg'
import mixmaster_gold from  '../assets/synergy-svg/gold/24_mixmaster.svg'
import mixmaster_green from  '../assets/synergy-svg/green/24_mixmaster.svg'
import pentakill_grey from  '../assets/synergy-svg/grey/24_pentakill.svg'
import pentakill_bronze from  '../assets/synergy-svg/bronze/24_pentakill.svg'
import pentakill_silver from  '../assets/synergy-svg/silver/24_pentakill.svg'
import pentakill_gold from  '../assets/synergy-svg/gold/24_pentakill.svg'
import pentakill_green from  '../assets/synergy-svg/green/24_pentakill.svg'
import punk_grey from  '../assets/synergy-svg/grey/24_punk.svg'
import punk_bronze from  '../assets/synergy-svg/bronze/24_punk.svg'
import punk_silver from  '../assets/synergy-svg/silver/24_punk.svg'
import punk_gold from  '../assets/synergy-svg/gold/24_punk.svg'
import punk_green from  '../assets/synergy-svg/green/24_punk.svg'
import true_damage_grey from  '../assets/synergy-svg/grey/24_true_damage.svg'
import true_damage_bronze from  '../assets/synergy-svg/bronze/24_true_damage.svg'
import true_damage_silver from  '../assets/synergy-svg/silver/24_true_damage.svg'
import true_damage_gold from  '../assets/synergy-svg/gold/24_true_damage.svg'
import true_damage_green from  '../assets/synergy-svg/green/24_true_damage.svg'
import wildcard_grey from  '../assets/synergy-svg/grey/24_wildcard.svg'
import wildcard_bronze from  '../assets/synergy-svg/bronze/24_wildcard.svg'
import wildcard_silver from  '../assets/synergy-svg/silver/24_wildcard.svg'
import wildcard_gold from  '../assets/synergy-svg/gold/24_wildcard.svg'
import wildcard_green from  '../assets/synergy-svg/green/24_wildcard.svg'
import big_shot_grey from  '../assets/synergy-svg/grey/24_big_shot.svg'
import big_shot_bronze from  '../assets/synergy-svg/bronze/24_big_shot.svg'
import big_shot_silver from  '../assets/synergy-svg/silver/24_big_shot.svg'
import big_shot_gold from  '../assets/synergy-svg/gold/24_big_shot.svg'
import big_shot_green from  '../assets/synergy-svg/green/24_big_shot.svg'
import bruiser_grey from  '../assets/synergy-svg/grey/24_bruiser.svg'
import bruiser_bronze from  '../assets/synergy-svg/bronze/24_bruiser.svg'
import bruiser_silver from  '../assets/synergy-svg/silver/24_bruiser.svg'
import bruiser_gold from  '../assets/synergy-svg/gold/24_bruiser.svg'
import bruiser_green from  '../assets/synergy-svg/green/24_bruiser.svg'
import crowd_diver_grey from  '../assets/synergy-svg/grey/24_crowd_diver.svg'
import crowd_diver_bronze from  '../assets/synergy-svg/bronze/24_crowd_diver.svg'
import crowd_diver_silver from  '../assets/synergy-svg/silver/24_crowd_diver.svg'
import crowd_diver_gold from  '../assets/synergy-svg/gold/24_crowd_diver.svg'
import crowd_diver_green from  '../assets/synergy-svg/green/24_crowd_diver.svg'
import dazzler_grey from  '../assets/synergy-svg/grey/24_dazzler.svg'
import dazzler_bronze from  '../assets/synergy-svg/bronze/24_dazzler.svg'
import dazzler_silver from  '../assets/synergy-svg/silver/24_dazzler.svg'
import dazzler_gold from  '../assets/synergy-svg/gold/24_dazzler.svg'
import dazzler_green from  '../assets/synergy-svg/green/24_dazzler.svg'
import edgelord_grey from  '../assets/synergy-svg/grey/24_edgelord.svg'
import edgelord_bronze from  '../assets/synergy-svg/bronze/24_edgelord.svg'
import edgelord_silver from  '../assets/synergy-svg/silver/24_edgelord.svg'
import edgelord_gold from  '../assets/synergy-svg/gold/24_edgelord.svg'
import edgelord_green from  '../assets/synergy-svg/green/24_edgelord.svg'
import executioner_grey from  '../assets/synergy-svg/grey/24_executioner.svg'
import executioner_bronze from  '../assets/synergy-svg/bronze/24_executioner.svg'
import executioner_silver from  '../assets/synergy-svg/silver/24_executioner.svg'
import executioner_gold from  '../assets/synergy-svg/gold/24_executioner.svg'
import executioner_green from  '../assets/synergy-svg/green/24_executioner.svg'
import guardian_grey from  '../assets/synergy-svg/grey/24_guardian.svg'
import guardian_bronze from  '../assets/synergy-svg/bronze/24_guardian.svg'
import guardian_silver from  '../assets/synergy-svg/silver/24_guardian.svg'
import guardian_gold from  '../assets/synergy-svg/gold/24_guardian.svg'
import guardian_green from  '../assets/synergy-svg/green/24_guardian.svg'
import mosher_grey from  '../assets/synergy-svg/grey/24_mosher.svg'
import mosher_bronze from  '../assets/synergy-svg/bronze/24_mosher.svg'
import mosher_silver from  '../assets/synergy-svg/silver/24_mosher.svg'
import mosher_gold from  '../assets/synergy-svg/gold/24_mosher.svg'
import mosher_green from  '../assets/synergy-svg/green/24_mosher.svg'
import rapidfire_grey from  '../assets/synergy-svg/grey/24_rapidfire.svg'
import rapidfire_bronze from  '../assets/synergy-svg/bronze/24_rapidfire.svg'
import rapidfire_silver from  '../assets/synergy-svg/silver/24_rapidfire.svg'
import rapidfire_gold from  '../assets/synergy-svg/gold/24_rapidfire.svg'
import rapidfire_green from  '../assets/synergy-svg/green/24_rapidfire.svg'
import sentinel_grey from  '../assets/synergy-svg/grey/24_sentinel.svg'
import sentinel_bronze from  '../assets/synergy-svg/bronze/24_sentinel.svg'
import sentinel_silver from  '../assets/synergy-svg/silver/24_sentinel.svg'
import sentinel_gold from  '../assets/synergy-svg/gold/24_sentinel.svg'
import sentinel_green from  '../assets/synergy-svg/green/24_sentinel.svg'
import spellweaver_grey from  '../assets/synergy-svg/grey/24_spellweaver.svg'
import spellweaver_bronze from  '../assets/synergy-svg/bronze/24_spellweaver.svg'
import spellweaver_silver from  '../assets/synergy-svg/silver/24_spellweaver.svg'
import spellweaver_gold from  '../assets/synergy-svg/gold/24_spellweaver.svg'
import spellweaver_green from  '../assets/synergy-svg/green/24_spellweaver.svg'
import superfan_grey from  '../assets/synergy-svg/grey/24_superfan.svg'
import superfan_bronze from  '../assets/synergy-svg/bronze/24_superfan.svg'
import superfan_silver from  '../assets/synergy-svg/silver/24_superfan.svg'
import superfan_gold from  '../assets/synergy-svg/gold/24_superfan.svg'
import superfan_green from  '../assets/synergy-svg/green/24_superfan.svg'

var synergyList = {
    "8bit-grey" : eight_bit_grey,
    "8bit-bronze" : eight_bit_bronze,
    "8bit-silver" : eight_bit_silver,
    "8bit-gold" : eight_bit_gold,
    "8bit-green" : eight_bit_green,
    "breakout-grey" : breakout_grey,
    "breakout-bronze" : breakout_bronze,
    "breakout-silver" : breakout_silver,
    "breakout-gold" : breakout_gold,
    "breakout-green" : breakout_green,
    "country-grey" : country_grey,
    "country-bronze" : country_bronze,
    "country-silver" : country_silver,
    "country-gold" : country_gold,
    "country-green" : country_green,
    "disco-grey" : disco_grey,
    "disco-bronze" : disco_bronze,
    "disco-silver" : disco_silver,
    "disco-gold" : disco_gold,
    "disco-green" : disco_green,
    "edm-grey" : edm_grey,
    "edm-bronze" : edm_bronze,
    "edm-silver" : edm_silver,
    "edm-gold" : edm_gold,
    "edm-green" : edm_green,
    "emo-grey" : emo_grey,
    "emo-bronze" : emo_bronze,
    "emo-silver" : emo_silver,
    "emo-gold" : emo_gold,
    "emo-green" : emo_green,
    "heartsteel-grey" : heartsteel_grey,
    "heartsteel-bronze" : heartsteel_bronze,
    "heartsteel-silver" : heartsteel_silver,
    "heartsteel-gold" : heartsteel_gold,
    "heartsteel-green" : heartsteel_green,
    "hyperpop-grey" : hyperpop_grey,
    "hyperpop-bronze" : hyperpop_bronze,
    "hyperpop-silver" : hyperpop_silver,
    "hyperpop-gold" : hyperpop_gold,
    "hyperpop-green" : hyperpop_green,
    "illbeats-grey" : illbeats_grey,
    "illbeats-bronze" : illbeats_bronze,
    "illbeats-silver" : illbeats_silver,
    "illbeats-gold" : illbeats_gold,
    "illbeats-green" : illbeats_green,
    "jazz-grey" : jazz_grey,
    "jazz-bronze" : jazz_bronze,
    "jazz-silver" : jazz_silver,
    "jazz-gold" : jazz_gold,
    "jazz-green" : jazz_green,
    "kda-grey" : kda_grey,
    "kda-bronze" : kda_bronze,
    "kda-silver" : kda_silver,
    "kda-gold" : kda_gold,
    "kda-green" : kda_green,
    "maestro-grey" : maestro_grey,
    "maestro-bronze" : maestro_bronze,
    "maestro-silver" : maestro_silver,
    "maestro-gold" : maestro_gold,
    "maestro-green" : maestro_green,
    "mixmaster-grey" : mixmaster_grey,
    "mixmaster-bronze" : mixmaster_bronze,
    "mixmaster-silver" : mixmaster_silver,
    "mixmaster-gold" : mixmaster_gold,
    "mixmaster-green" : mixmaster_green,
    "pentakill-grey" : pentakill_grey,
    "pentakill-bronze" : pentakill_bronze,
    "pentakill-silver" : pentakill_silver,
    "pentakill-gold" : pentakill_gold,
    "pentakill-green" : pentakill_green,
    "punk-grey" : punk_grey,
    "punk-bronze" : punk_bronze,
    "punk-silver" : punk_silver,
    "punk-gold" : punk_gold,
    "punk-green" : punk_green,
    "truedamage-grey" : true_damage_grey,
    "truedamage-bronze" : true_damage_bronze,
    "truedamage-silver" : true_damage_silver,
    "truedamage-gold" : true_damage_gold,
    "truedamage-green" : true_damage_green,
    "wildcard-grey" : wildcard_grey,
    "wildcard-bronze" : wildcard_bronze,
    "wildcard-silver" : wildcard_silver,
    "wildcard-gold" : wildcard_gold,
    "wildcard-green" : wildcard_green,
    "bigshot-grey" : big_shot_grey,
    "bigshot-bronze" : big_shot_bronze,
    "bigshot-silver" : big_shot_silver,
    "bigshot-gold" : big_shot_gold,
    "bigshot-green" : big_shot_green,
    "bruiser-grey" : bruiser_grey,
    "bruiser-bronze" : bruiser_bronze,
    "bruiser-silver" : bruiser_silver,
    "bruiser-gold" : bruiser_gold,
    "bruiser-green" : bruiser_green,
    "crowddiver-grey" : crowd_diver_grey,
    "crowddiver-bronze" : crowd_diver_bronze,
    "crowddiver-silver" : crowd_diver_silver,
    "crowddiver-gold" : crowd_diver_gold,
    "crowddiver-green" : crowd_diver_green,
    "dazzler-grey" : dazzler_grey,
    "dazzler-bronze" : dazzler_bronze,
    "dazzler-silver" : dazzler_silver,
    "dazzler-gold" : dazzler_gold,
    "dazzler-green" : dazzler_green,
    "edgelord-grey" : edgelord_grey,
    "edgelord-bronze" : edgelord_bronze,
    "edgelord-silver" : edgelord_silver,
    "edgelord-gold" : edgelord_gold,
    "edgelord-green" : edgelord_green,
    "executioner-grey" : executioner_grey,
    "executioner-bronze" : executioner_bronze,
    "executioner-silver" : executioner_silver,
    "executioner-gold" : executioner_gold,
    "executioner-green" : executioner_green,
    "guardian-grey" : guardian_grey,
    "guardian-bronze" : guardian_bronze,
    "guardian-silver" : guardian_silver,
    "guardian-gold" : guardian_gold,
    "guardian-green" : guardian_green,
    "mosher-grey" : mosher_grey,
    "mosher-bronze" : mosher_bronze,
    "mosher-silver" : mosher_silver,
    "mosher-gold" : mosher_gold,
    "mosher-green" : mosher_green,
    "rapidfire-grey" : rapidfire_grey,
    "rapidfire-bronze" : rapidfire_bronze,
    "rapidfire-silver" : rapidfire_silver,
    "rapidfire-gold" : rapidfire_gold,
    "rapidfire-green" : rapidfire_green,
    "sentinel-grey" : sentinel_grey,
    "sentinel-bronze" : sentinel_bronze,
    "sentinel-silver" : sentinel_silver,
    "sentinel-gold" : sentinel_gold,
    "sentinel-green" : sentinel_green,
    "spellweaver-grey" : spellweaver_grey,
    "spellweaver-bronze" : spellweaver_bronze,
    "spellweaver-silver" : spellweaver_silver,
    "spellweaver-gold" : spellweaver_gold,
    "spellweaver-green" : spellweaver_green,
    "superfan-grey" : superfan_grey,
    "superfan-bronze" : superfan_bronze,
    "superfan-silver" : superfan_silver,
    "superfan-gold" : superfan_gold,
    "superfan-green" : superfan_green,
}

const Graph = lazy(() => import('vis-react'));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30,33,36)' : '#FFFFFF',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30,33,36)' : 'rgb(30,33,36)',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30,33,36)' : '#FFFFFF',
      borderRadius: 20 / 2,
    },
    '& .MuiSwitch-label': { // assuming 'MuiSwitch-label' is the class name for your text component
        color: '#fff',
      },
  }));

const StyledDivider = styled(Divider)({
    background: "rgba(256, 256, 256, 0.2)",
    height: "1px",
    m: 10,
    width: "250px",
    marginTop:"10px",
    marginBottom:"14px"
});
const CustomBackdrop = styled(Backdrop)({
    backgroundColor: 'rgba(0, 0, 0, 0)', // fully transparent
  });

const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
      backgroundColor: "#121212",
    },
    '& .MuiBackdrop-root' : {
        background: 'transparent',
        opacity: 0.5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)', 

      }
 });

const buttonAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const AnimatedButton = styled(Button)`
  &.button-animated {
    animation: ${buttonAnimation} 3s linear 4;
  }
`;

const StyledBottomNavigation = styled(BottomNavigation)({
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#121212',
})

function WhiteCircularProgress(){  return ( <CircularProgress style={{'color': 'white'}}/>) }
const renderLoader = () => <div></div>

const EXPLORATION_QUERY = gql`
  query explore($name: String) {
    champions(where: { id: $name }) {
      id
      name
      imageSrc
      health1
      mana1
      armor1
      mr1
      dps1
      atkSpd1
      range1
      damage1
      health2
      mana2
      armor2
      mr2
      dps2
      atkSpd2
      range2
      damage2
      health3
      mana3
      armor3
      mr3
      dps3
      atkSpd3
      range3
      damage3
      synergies {
        id
        name
        imageSrc
        counts
      }
      cost
      tier
      recommendedItemsConnection {
        edges {
          team
          node {
            id
            name
            imageSrc
          }
        }
      }
    }
  }
`;

const EXPLORATION_QUERY_WITH_TEAM = gql`
  query explore($champName: String, $teamName: String) {
    champions(where: { id: $champName }) {
      id
      name
      imageSrc
      health1
      mana1
      armor1
      mr1
      dps1
      atkSpd1
      range1
      damage1
      health2
      mana2
      armor2
      mr2
      dps2
      atkSpd2
      range2
      damage2
      health3
      mana3
      armor3
      mr3
      dps3
      atkSpd3
      range3
      damage3
      synergies {
        id
        name
        imageSrc
        counts
      }
      cost
      tier
      recommendedItemsConnection(where: {edge: {team: $teamName }}) {
        edges {
          team
          node {
            id
            name
            imageSrc
          }
        }
      }
      memberOfConnection(where: {node: {id: $teamName}}) {
        edges {
            position
            level
        }
      }
    }
  }
`;

const CHAMPION_BY_SYNERGY_QUERY = gql`
  query explore($synergy: String) {
    champions(where: {synergies: {id: $synergy } }) {
        id
        name
        imageSrc
        health1
        mana1
        armor1
        mr1
        dps1
        atkSpd1
        range1
        damage1
        health2
        mana2
        armor2
        mr2
        dps2
        atkSpd2
        range2
        damage2
        health3
        mana3
        armor3
        mr3
        dps3
        atkSpd3
        range3
        damage3
        synergies {
            id
            name
            imageSrc
            counts
            champions {
                id
                name
            }
        }
        cost
        tier
    }
  }
`;

const CHAMPION_BY_RECOMMENDED_FOR = gql`
  query explore($recommendedFor: String) {
    itemCombineds(where: {id: $recommendedFor }) {
        id
        name
        recommendedFor {
            id
            name
            imageSrc
            synergies {
                id
                name
                imageSrc
                counts
                champions {
                    id
                    name
                    imageSrc
                }
            }
        }
    }
  }
`;

const COMBINED_ITEM_BY_BASIC_ITEM = gql`
  query explore($basicItem: String) {
    itemBasics(where: {id: $basicItem}) {
        id
        name
        builds {
            id
            name
            imageSrc
        }
    }
  }
`;

const BASIC_ITEM_BY_COMBINED_ITEM = gql`
  query explore($combinedItem: String) {
    itemCombineds (where: {id: $combinedItem}) {
        id
        name
        builtBy {
            id
            name
            imageSrc
        }
    }
  }
`;

const CHARACTER_POPUP_QUERY = gql`
  query explore($name: String) {
    champions(where: { id: $name }) {
        id
        imageSrc
        name      
        synergies {
            id
            name
            counts
        }
        cost
        tier
        recommendedItems {
            id
            name
            imageSrc
        }
    }
  }
`;

const SYNERGY_POPUP_QUERY = gql`
  query explore($name: String) {
    synergies(where: { id: $name }) {
        id
        name
        description
        imageSrc
        champions {
            imageSrc
            name
            id
            health1
            mana1
            armor1
            mr1
            dps1
            atkSpd1
            range1
            damage1
            health2
            mana2
            armor2
            mr2
            dps2
            atkSpd2
            range2
            damage2
            health3
            mana3
            armor3
            mr3
            dps3
            atkSpd3
            range3
            damage3
            cost
        }
        bonus
        counts
    }
  }
`;

const GET_ALL_CHAMPIONS = gql`
     query GetAllChampions {
        champions{
            id
            name
            imageSrc
            cost
            health1
            mana1
            armor1
            mr1
            dps1
            atkSpd1
            range1
            damage1
            health2
            mana2
            armor2
            mr2
            dps2
            atkSpd2
            range2
            damage2
            health3
            mana3
            armor3
            mr3
            dps3
            atkSpd3
            range3
            damage3
            synergies {
                name
            }
        }
     }
`;

const GET_ITEM_BASIC = gql`
  query explore($name: String) {
    itemBasics(where: { id: $name }) {
        id
        imageSrc
        name
        bonus
        tier
        builds{
            id
            name
            imageSrc
        }
    }
  }
`;

const GET_ITEM_COMBINED = gql`
  query explore($name: String) {
    itemCombineds(where: { id: $name}) {
        id
        name
        bonus
        tier
        imageSrc
        builtBy {
            id
            name
            imageSrc
        }
        recommendedFor {
            id
            name
            imageSrc
            cost
            health3
            mana3
            armor3
            mr3
            dps3
            atkSpd3
            range3
            damage3 
        }
    }
  }
`;

const GET_TEAM_WITH_ENCODING = gql`
query Query($username: String, $encoding: String) {
    teams(where: { encoding: $encoding, createdBy: { id: $username } }) {
      id
    }
  }
  `;

const GET_TEAM_1 = gql`
query GetTeams($champ1: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
          position
          node {
            id
            name
            imageSrc
            cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_2 = gql`
query GetTeams($champ1: String, $champ2: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {membersConnection_SOME: {node: {id: $champ2}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_3 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_4 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_5 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $champ5: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {membersConnection_SOME:{node: {id: $champ5}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_6 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $champ5: String, $champ6: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME: {node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {membersConnection_SOME:{node: {id: $champ5}}},
        {membersConnection_SOME:{node: {id: $champ6}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_7 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $champ5: String, $champ6: String, $champ7: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME:{node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {membersConnection_SOME:{node: {id: $champ5}}},
        {membersConnection_SOME:{node: {id: $champ6}}},
        {membersConnection_SOME:{node: {id: $champ7}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;
const GET_TEAM_8 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $champ5: String, $champ6: String, $champ7: String, $champ8: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME:{node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {membersConnection_SOME:{node: {id: $champ5}}},
        {membersConnection_SOME:{node: {id: $champ6}}},
        {membersConnection_SOME:{node: {id: $champ7}}},
        {membersConnection_SOME:{node: {id: $champ8}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;
const GET_TEAM_9 = gql`
query GetTeams($champ1: String, $champ2: String, $champ3: String, $champ4: String, $champ5: String, $champ6: String, $champ7: String, $champ8: String, $champ9: String, $type: String, $username: String) {
    teams(where: {
      AND: [
        {membersConnection_SOME:{node: {id: $champ1}}},
        {membersConnection_SOME:{node: {id: $champ2}}},
        {membersConnection_SOME:{node: {id: $champ3}}},
        {membersConnection_SOME:{node: {id: $champ4}}},
        {membersConnection_SOME:{node: {id: $champ5}}},
        {membersConnection_SOME:{node: {id: $champ6}}},
        {membersConnection_SOME:{node: {id: $champ7}}},
        {membersConnection_SOME:{node: {id: $champ8}}},
        {membersConnection_SOME:{node: {id: $champ9}}},
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
            position
            node {
                id
                name
                imageSrc
                cost
          }
        }
      }
    }
  }
`;

const GET_TEAM_ALL = gql`
query GetTeams($type: String, $username: String) {
    teams(where: {
      AND: [
        {type: $type},
        {createdBy: {id: $username}}
      ]
    }) {
      id
      name
      winRate
      top4Rate
      membersConnection {
        edges {
          position
          node {
            id
            name
            imageSrc
            cost
          }
        }
      }
    }
  }
`;

const ADD_MEMBER = gql`
mutation($teamId: String!, $champId: String!, $position: Int!, $level: Int!) {
    updateTeams(
        where: { id: $teamId }
        update: {
        members: {
            connectOrCreate: {
            where: { 
                node: { 
                id: $champId 
                }
                }
            onCreate: { 
                node: { 
                id: $champId
                }
                edge: {
                position: $position
                level: $level
                }
            }
            }
        }
        },
    ) {
        teams {
        id
        members {
            id
        }
        }
    }
}
`;

const ADD_TEAM = gql`
mutation($id: String!, $name: String!, $createdBy: String!, $encoding: String!) {
    createTeams(input: [{
      id: $id,
      type: "user",
      name: $name,
      encoding: $encoding,
      createdBy: {
        connectOrCreate: {
          where: { node: { id: $createdBy } }
          onCreate: { node: { id: $createdBy } }
        }
      }
    }]){
      teams {
        id
        type
        name
        createdBy {
          id
        }
      }
    }
  }
`;

const REMOVE_TEAM = gql`
mutation($id: String!) {
    deleteTeams(where: {
        id: $id
    }) {
        nodesDeleted
      }
}
`;

export default function Exploration(props) {
    const location = useLocation();
    const user = useContext(AuthContext);

    useEffect(() => {
        if (user) {
            setLoggedIn(true)
        } 
    }, [user])

    const meta = {
        title: 'TFT Set 10 Team Builder - Teamfight Tactics - GraphTFT',
        description: 'Team builder for creating the best team comps for TFT Set 10',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'tft team builder, tft, team builder, set 10, tft mid set,teamfight tactics, tft team planner, tft graph, tft builder, tft planner, tft comps'
            }
        }
      }

    const client = useApolloClient();
    const [nodeObject, setNodeObject] = useState();
    const [edgeObject, setEdgeObject] = useState();
    const [allNodes, setAllNodes] = useState([]);
    const [newNode, setNode] = useState( { id: "", cost: "", name:"", imageSrc:"" } );
    const [fromChampionNode, setFromChampionNode] = useState( { id: "", cost: "", name:"", imageSrc:"" } );
    const [allSynergies, setAllSynergies] = useState([]);
    const [allEdges, setAllEdges] = useState([]);
    const [health, setHealth] = useState(0);
    const [mana, setMana] = useState(0);
    const [armor, setArmor] = useState(0);
    const [mr, setMr] = useState(0);
    const [dps, setDps] = useState(0);
    const [atkSpd, setAtkSpd] = useState(0);
    const [damage, setDamage] = useState(0);
    const [range, setRange] = useState(0);
    const [currentNode, setCurrentNode] = useState([]);
    const [nodeToDelete, setNodeToDelete] = useState();
    const [nodeToAddFromClick, setNodeToAddFromClick] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hideItemNodes, setHideItemNodes] = useState(true)
    const [uniqueChampions, setUniqueChampions] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
    const [selectedType, setSelectedType] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const [openCopyAlert, setCopyAlert] = useState(false);
    const [openCreateAccount, setCreateAccountAlert] = useState(false);
    const [openTooManyChamps, setTooManyChampsAlert] = useState(false);
    const [openTooFewChamps, setTooFewChampsAlert] = useState(false);
    const [teamSaved, setTeamSaved] = useState(false);
    const [teamSaving, setTeamSaving] = useState(false);
    const [teamCompSource, setTeamCompSouce] = useState("meta");

    const handleSourceChange = (event) => {
      setTeamCompSouce(event.target.value);
    };
    const inputRef = useRef(null);
    
    const handleClick = () => {
      setShouldAutoFocus(true);
      inputRef.current.focus();
    };
  
    const handleBlur = () => {
      setShouldAutoFocus(false);
    };

    async function removeTeam(teamId) {
        const result = await client.mutate({
            mutation: REMOVE_TEAM,
            variables: { id: teamId },
        });
        return null;
    }

    async function addTeam(teamId, teamName, encoding) {
        const result = await client.mutate({
            mutation: ADD_TEAM,
            variables: { id: teamId, name: teamName, createdBy: user.email, encoding: encoding },
        });
        return null;
    }

    async function addMember(teamId, champId, position, level) {
        const result = await client.mutate({
            mutation: ADD_MEMBER,
            variables: { teamId: teamId, champId: champId, position: position, level: level },
        });
        return null;
    }

    function capitalizeWords(str) {
        return str.replace(/\b\w/g, (match) => match.toUpperCase());
      }

    function generateTeamName(team) {
        const champIds = team.champions.map((champ) => champ.id).sort();
        const teamName = champIds.join(", ");
        const capitalizedTeamName = capitalizeWords(teamName);
        return capitalizedTeamName;
      }

      async function handleSaveTeam() {
        if (!loggedIn) {
          setCreateAccountAlert(true);
        } else if (teamSaved) {
          handleUnsaveTeam();
        } else {
          var team = teamToJSON();
          if (team.champions.length > 9) {
            setTooManyChampsAlert(true)
          } else if (team.champions.length < 1) {
            setTooFewChampsAlert(true)
          } else {
            setTeamSaving(true);
            var encoding = customEncode(team);
            const teamName = generateTeamName(team);
            const teamId = "team#" + user.email + "#" + teamName;
            try {
              await addTeam(teamId, teamName, encoding);
              await Promise.all(
                team.champions.map(async (champ) => {
                  await addMember(
                    teamId,
                    "champion#" + champ.id,
                    parseInt(champ.position),
                    champ.level
                  );
                })
              );
              setTeamSaving(false);
              setTeamSaved(true);
              
            } catch (error) {
              console.error(error);
            }
          }
        }
      }      

      async function handleUnsaveTeam() {
        if (!loggedIn) {
          setCreateAccountAlert(true);
        } else {
          setTeamSaving(true);
          var team = teamToJSON();
          const teamName = generateTeamName(team);
          const teamId = "team#" + user.email + "#" + teamName;
          try {
            await removeTeam(teamId);
            setTeamSaving(false);
            setTeamSaved(false);
          } catch (error) {
            console.error(error);
          }
        }
      }

    const [valueX, setValueX] = useState(1);
    const clearGraph = () => (event) => {
        edgeObject.clear();
        nodeObject.clear();
        setUniqueChampions([]);
        setHealth(0)
        setMana(0)
        setArmor(0)
        setMr(0)
        setDps(0)
        setAtkSpd(0)
        setDamage(0)
        setRange(0)        
        setAllNodes([]);
        setAllEdges([]);
        setAllSynergies([]);
        setSelectedType();
        setTeamSaved(false);
        setTeamSaving(false);
        setHexImage1({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage2({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage3({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage4({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage5({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage6({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage7({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage8({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage9({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage10({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage11({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage12({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage13({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage14({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage15({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage16({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage17({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage18({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage19({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage20({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage21({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage22({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage23({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage24({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage25({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage26({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage27({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage28({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
    }

    const handleStar1 = (from, champion, position) => {
        if (from === 2) {
            removeTeamStats(champion, 2)
            addTeamStats(Object.assign({}, champion, {star:1}))
            setImagesList[position](Object.assign({}, champion, {star:1}))
        } else if (from === 3){
            removeTeamStats(champion, 3)
            addTeamStats(Object.assign({}, champion, {star:1}))
            setImagesList[position](Object.assign({}, champion, {star:1}))
        }
      };
    
      const handleStar2 = (from, champion, position) => {
        if (from === 1) {
            removeTeamStats(champion, 1)
            addTeamStats(Object.assign({}, champion, {star:2}))
            setImagesList[position](Object.assign({}, champion, {star:2}))
        } else if (from === 3){
            removeTeamStats(champion, 3)
            addTeamStats(Object.assign({}, champion, {star:2}))
            setImagesList[position](Object.assign({}, champion, {star:2}))
        }
      };
    
      const handleStar3 = (from, champion, position) => {
        if (from === 1) {
            removeTeamStats(champion, 1)
            addTeamStats(Object.assign({}, champion, {star:3}))
            setImagesList[position](Object.assign({}, champion, {star:3}))
        } else if (from === 2){
            removeTeamStats(champion, 2)
            addTeamStats(Object.assign({}, champion, {star:3}))
            setImagesList[position](Object.assign({}, champion, {star:3}))
        }
      };

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open });
        return null;
    }
  
    const [fetchCharacterPopupData, { data: characterData }] =
        useLazyQuery(CHARACTER_POPUP_QUERY);
    const [fetchSynergyPopupData, { data: synergyData }] =
        useLazyQuery(SYNERGY_POPUP_QUERY);  
    const [fetchItemBasicPopupData, {data: itemBasicData }] = 
        useLazyQuery(GET_ITEM_BASIC);
    const [fetchItemCombinedPopupData, {data: itemCombinedData }] = 
        useLazyQuery(GET_ITEM_COMBINED);
    const [fetchRecommended1] =
        useLazyQuery(GET_TEAM_1);
    const [fetchRecommended2] =
        useLazyQuery(GET_TEAM_2);
    const [fetchRecommended3] =
        useLazyQuery(GET_TEAM_3);      
    const [fetchRecommended4] =
        useLazyQuery(GET_TEAM_4);
    const [fetchRecommended5] =
        useLazyQuery(GET_TEAM_5);
    const [fetchRecommended6] =
        useLazyQuery(GET_TEAM_6);
    const [fetchRecommended7] =
        useLazyQuery(GET_TEAM_7);      
    const [fetchRecommended8] =
        useLazyQuery(GET_TEAM_8);
    const [fetchRecommended9] =
        useLazyQuery(GET_TEAM_9);        
    const [fetchRecommendedAll] =
        useLazyQuery(GET_TEAM_ALL);          
        
    const [recommendedTeams, setRecommendedTeams] = useState();
    const [loadingRecommendations, setLoadingRecommendations] = useState(true);

    useEffect(() => {
        setLoadingRecommendations(true);
      
        if (uniqueChampions.length === 0) {
          fetchRecommendedAll({ variables: { type: teamCompSource }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 1) {
          fetchRecommended1({  variables: { 
            champ1: uniqueChampions[0], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email 
          }, fetchPolicy: "no-cache" 
        }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 2) {
          fetchRecommended2({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 3) {
          fetchRecommended3({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 4) {
          fetchRecommended4({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 5) {
          fetchRecommended5({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 6) {
          fetchRecommended6({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], champ6: uniqueChampions[5], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 7) {
          fetchRecommended7({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], champ6: uniqueChampions[5], champ7: uniqueChampions[6], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 8) {
          fetchRecommended8({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], champ6: uniqueChampions[5], champ7: uniqueChampions[6], champ8: uniqueChampions[7], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 8) {
          fetchRecommended8({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], champ6: uniqueChampions[5], champ7: uniqueChampions[6], champ8: uniqueChampions[7], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
            setRecommendedTeams(data);
            setLoadingRecommendations(false);
          });
        } else if (uniqueChampions.length === 9) {
        fetchRecommended9({ variables: { champ1: uniqueChampions[0], champ2: uniqueChampions[1], champ3: uniqueChampions[2], champ4: uniqueChampions[3], champ5: uniqueChampions[4], champ6: uniqueChampions[5], champ7: uniqueChampions[6], champ8: uniqueChampions[7], champ9: uniqueChampions[8], 
            type: teamCompSource, 
            username: teamCompSource === "meta" ? "meta" : user.email  }, fetchPolicy: "no-cache"  }).then(data => {
          setRecommendedTeams(data);
          setLoadingRecommendations(false);
        });
      }        
      }, [uniqueChampions, fetchRecommended1, fetchRecommended2, fetchRecommended3, fetchRecommended4, fetchRecommended5, fetchRecommended6, fetchRecommended7, fetchRecommended8, fetchRecommended9, fetchRecommendedAll, teamSaved, teamCompSource]); // eslint-disable-line react-hooks/exhaustive-deps
      

    const graph = { nodes: [], edges: [] };

    const getNodes = (nodes) => {
        setNodeObject(nodes);
    };
    
    const getEdges = (edges) => {
        setEdgeObject(edges);
    };

    const getNetwork = (network) => {
        const localNetwork = network;
        localNetwork.setOptions(options);
        localNetwork.on("click", (params) => {
            var current = params.nodes[0];
            const group = (current) ? network.body.data.nodes._data[current].group:null;
            if (
                current && group === 'Champion'
            ) {
                fetchCharacterPopupData({ variables: { name: current } });
                setCurrentNode(current);
                setSelectedType("champion")
            }
            else if (
                current && group === 'Synergy'
            ){
                fetchSynergyPopupData({ variables: { name: current } });
                setCurrentNode(current);
                setSelectedType("synergy")
            }
            else if (
                current && group === 'ItemBasic'
            ){
                fetchItemBasicPopupData({ variables: { name: current } });
                setCurrentNode(current);
                setSelectedType("itemBasic")

            }
            else if (
                current && group === 'ItemCombined'
            ){
                fetchItemCombinedPopupData({ variables: { name: current } });
                setCurrentNode(current);
                setSelectedType("itemCombined")

            }
            else {
                setSelectedType()
            }                
        });
        localNetwork.on("afterDrawing", (params) => {
            network.fit();
        })
    };      

    async function addCharacter(championId) {
        const rels = await client.query({
            query: EXPLORATION_QUERY,
            variables: { name: championId },
        });
        if (rels.data && rels.data.champions) {
            rels.data.champions.map((el) => {
                setFromChampionNode(el)
                let champWithStar = Object.assign({}, el, {star:1})
                setNode(champWithStar)
                if (!allNodes.includes(el.id)) {
                    let synergyNodes = rels.data.champions[0].synergies;
                    synergyNodes.map((sy) => {
                        console.log(sy)
                        setNode(sy)
                    return null;
                    });

                    // should only add items that are related to the team or null
                    let combinedItems = rels.data.champions[0].recommendedItemsConnection.edges;
                    combinedItems.map((item) => {
                        setNode(item)
                    return null
                    });                      
                }
                return null;
                }
            );
        }        
    };

    async function addCharacterFromTeam(championId, teamId) {
        const rels = await client.query({
            query: EXPLORATION_QUERY_WITH_TEAM,
            variables: { teamName: teamId, champName: championId },
        });
        if (rels.data && rels.data.champions) {
            rels.data.champions.map((el) => {
                setFromChampionNode(el)
                setNode(el)
                if (true) { 
                    let synergyNodes = rels.data.champions[0].synergies;
                    synergyNodes.map((sy) => {
                        setNode(sy)
                    return null;
                    });
                    // should only add items that are related to the team or null
                    let combinedItems = rels.data.champions[0].recommendedItemsConnection.edges;
                    combinedItems.map((item) => {
                        setNode(item)
                    return null
                    });                      
                }
                return null;
                }
            );
        }        
    };

    useEffect(()=>{
        if (newNode.id){
            if (!allNodes.includes(newNode.id) && newNode.id !== "" && newNode.id.includes("champion")) {
                var borderColour = (
                    newNode.cost === 1 ? "grey" : // if 
                    newNode.cost === 2 ? "green" : // else if 
                    newNode.cost === 3 ? "rgba(79, 163, 255, 0.8)" : // else if
                    newNode.cost === 4 ? "purple" :
                    newNode.cost === 5 ? "gold" :
                    "black" // else 
                  );
                setAllNodes((oldArray) => [...oldArray, newNode.id]);
                nodeObject.add({id: newNode.id, label: newNode.name, group: "Champion", shape: "image",  image: newNode.imageSrc,  borderWidth: 2, color:{border:borderColour} , shapeProperties: { useBorderWithImage:true}});
                if (!uniqueChampions.includes(newNode.id)) {
                        // Make a copy of the original array
                        const newArray = [...uniqueChampions];
                        // Modify the copy by adding a new element
                        newArray.push(newNode.id);
                        // Update the state with the new array
                        setUniqueChampions(newArray);
                }
            }
            // adding synergies
            if (!allNodes.includes(newNode.id) && newNode.id !== "" && newNode.id.includes("synergy")) {
                nodeObject.add({ id: newNode.id, label: newNode.name, group: "Synergy", shape: "image", image: synergyList[`${newNode.id.replace("synergy#", "").concat("-grey")}`], color:{border:"blue",background:"red"} });
                setAllNodes((oldArray) => [...oldArray, newNode.id]);
            }      
            if(!allEdges.includes(fromChampionNode.id + newNode.id) && newNode.id !== "" && newNode.id.includes("synergy")){
                edgeObject.add({ from: fromChampionNode.id, to: newNode.id, label: "SYNERGY" });
                setAllEdges((oldArray) => [...oldArray, fromChampionNode.id + newNode.id]);
                if (newNode.id.includes("synergy")) {
                    // if there are no synergies in the network
                    if (allSynergies.length === 0) {
                        setAllSynergies((oldArray) => {
                        var numberOfTiers = newNode.counts.length
                        if (numberOfTiers === 1) {
                            if (newNode.counts[0] === 1) {
                                nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                                return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: 1, counts: newNode.counts, backgroundColour: "gold", tier: 2}]
                            }
                            else {
                                nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-grey")}`]})
                                return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: 1, counts: newNode.counts, backgroundColour: "grey", tier: 5}]  
                            }
                          }
                        else {
                            return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc, count: 1, counts: newNode.counts, backgroundColour: "grey", tier: 5}]
                        }
                    })}
                    // if the added synergy is already in the network         
                    else if (allSynergies.map( (x) => x.id ).includes(newNode.id) ) {
                        setAllSynergies((oldArray) => {
                            var oldArrayLessId = oldArray.filter(function (y) {
                                return y.id !== newNode.id
                              });
                            var selectedSynergy = oldArray.filter(function (y) {
                                return y.id === newNode.id
                              })[0];
                            var bgColour = "grey"
                            var tier = 5
                            var numberOfTiers = newNode.counts.length
                            var tick = 1
                            if (numberOfTiers === 1) {
                                if (newNode.counts[0] === 1) {
                                    return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: "gold", tier: 2}]
                                }
                                else {
                                    newNode.counts.map((count) => {
                                        if (selectedSynergy.count + 1 >= count && tick === 1) {
                                            bgColour = "gold"
                                            nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size: 50})
                                            tier = 2
                                        }
                                        tick = tick + 1
                                        return null;
                                    })
        
                                    return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: bgColour, tier: tier}]
                                }
                              }
                            else if (numberOfTiers === 2) {
                                newNode.counts.map((count) => {
                                    if (selectedSynergy.count + 1 >= count && tick === 1) {
                                        bgColour = "rgba(206, 108, 0)"
                                        nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
                                        tier = 4
                                    }
                                    else if  (selectedSynergy.count + 1 >= count && tick === 2) {
                                        bgColour = "gold"
                                        nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                                        tier = 2
                                    }
                                    tick = tick + 1
                                    return null;
                                })
    
                                return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: bgColour, tier: tier}]
                              }
                            else if (numberOfTiers === 3) {
                                newNode.counts.map((count) => {
                                    if (selectedSynergy.count + 1 >= count && tick === 1) {
                                        tier = 4
                                        bgColour = "rgba(206, 108, 0)"
                                        nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
                                    }
                                    else if  (selectedSynergy.count + 1 >= count && tick === 2) {
                                        tier = 3
                                        bgColour = "silver"
                                        nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-silver")}`], size: 40 })
                                    }
                                    else if  (selectedSynergy.count + 1 >= count && tick === 3) {
                                        bgColour = "gold"
                                        tier = 2
                                        nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size:50 })
                                    }
                                    tick = tick + 1
                                    return null;
                                })
                                return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: bgColour, tier: tier}]
                            }
                            else if (numberOfTiers === 4) {
                                newNode.counts.map((count) => {
                                  if (selectedSynergy.count + 1 >= count && tick === 1) {
                                    bgColour = "rgba(206, 108, 0)"
                                    tier = 4
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
    
                                  }
                                  else if  (selectedSynergy.count + 1 >= count && tick === 2) {
                                    bgColour = "silver"
                                    tier = 3
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-silver")}`], size:40 })
    
                                  }
                                  else if  (selectedSynergy.count + 1 >= count && tick === 3) {
                                    bgColour = "gold"
                                    tier = 2
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size:50  })
    
                                  }
                                  else if (selectedSynergy.count + 1 >= count && tick === 4) {
                                    bgColour = "green"
                                    tier = 1
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-green")}`], size:60 })
    
                                  }
                                tick = tick + 1
                                return null
                                })
                                return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: bgColour, tier: tier}]
                              }
                            else if (numberOfTiers === 5) {
                                newNode.counts.map((count) => {
                                  if (selectedSynergy.count + 1 >= count && tick === 1) {
                                    bgColour = "rgba(206, 108, 0)"
                                    tier = 4
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
                                  }
                                  else if  (selectedSynergy.count + 1 >= count && tick === 2) {
                                    bgColour = "silver"
                                    tier = 3
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-silver")}`], size:40 })
    
                                  }
                                  else if  (selectedSynergy.count + 1 >= count && tick === 3) {
                                    bgColour = "gold"
                                    tier = 2
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size:50  })
    
                                  }
                                  else if (selectedSynergy.count + 1 >= count && tick === 4) {
                                    bgColour = "green"
                                    tier = 1
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-green")}`], size:60 })
                                  }
                                  else if (selectedSynergy.count + 1 >= count && tick === 5) {
                                    bgColour = "green"
                                    tier = 1
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-green")}`], size:60 })
                                  }
                                tick = tick + 1
                                return null
                                })
                                return [...oldArrayLessId, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: selectedSynergy.count + 1, counts: newNode.counts, backgroundColour: bgColour, tier: tier}]
                              }
                        })
                    }
                    // if there are synergies already, but the one added is new
                    else {
                        setAllSynergies((oldArray) => {
                            var numberOfTiers = newNode.counts.length
                            if (numberOfTiers === 1) {
                                if (newNode.counts[0] === 1) {
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                                    return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: 1, counts: newNode.counts, backgroundColour: "gold", tier: 2}]
                                }
                                else {
                                    nodeObject.update({id: newNode.id, image: synergyList[`${newNode.id.replace("synergy#", "").concat("-grey")}`]})
                                    return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc,  count: 1, counts: newNode.counts, backgroundColour: "grey", tier: 5}]  
                                }
                              }
                            else {
                                return [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc, count: 1, counts: newNode.counts, backgroundColour: "grey", tier: 5}]
                            }
                        })
                        // setAllSynergies((oldArray) => [...oldArray, {id: newNode.id, name: newNode.name, imageSrc: newNode.imageSrc, count: 1, counts: newNode.counts, backgroundColour: "grey"}])
                    }
                }  
            }
            if(newNode.memberOfConnection && newNode.memberOfConnection.edges[0].position !== 0) {
                addTeamStats(Object.assign({}, newNode, {star:newNode.memberOfConnection.edges[0].level}))
                setImagesList["hex".concat(newNode.memberOfConnection.edges[0].position)]({ 
                    id: newNode.id,
                    name: newNode.name,
                    imageSrc: newNode.imageSrc, 
                    cost: newNode.cost, 
                    health1: newNode.health1, 
                    mana1: newNode.mana1, 
                    armor1: newNode.armor1, 
                    mr1: newNode.mr1, 
                    dps1: newNode.dps1, 
                    atkSpd1: newNode.atkSpd1, 
                    range1: newNode.range1, 
                    damage1: newNode.damage1,
                    health2: newNode.health2, 
                    mana2: newNode.mana2, 
                    armor2: newNode.armor2, 
                    mr2: newNode.mr2, 
                    dps2: newNode.dps2, 
                    atkSpd2: newNode.atkSpd2, 
                    range2: newNode.range2, 
                    damage2: newNode.damage2,
                    health3: newNode.health3, 
                    mana3: newNode.mana3, 
                    armor3: newNode.armor3, 
                    mr3: newNode.mr3, 
                    dps3: newNode.dps3, 
                    atkSpd3: newNode.atkSpd3, 
                    range3: newNode.range3, 
                    damage3: newNode.damage3,
                    star: newNode.memberOfConnection.edges[0].level
                })
            }
        }
        // adding combined items
        if (newNode.node){
            if (!allNodes.includes(newNode.node.id) && newNode.node.id !== "" && newNode.node.id.includes("item") && !newNode.node.group) {
                nodeObject.add({ hidden: hideItemNodes, id: newNode.node.id, label: newNode.node.name, group: "ItemCombined", shape: "image", image: newNode.node.imageSrc });
                setAllNodes((oldArray) => [...oldArray, newNode.node.id]);
            }
            if (!allEdges.includes((fromChampionNode.id + newNode.node.id)) && newNode.node.id.includes("item")  && !newNode.node.group){
                edgeObject.add({
                    hidden: hideItemNodes,
                    from: fromChampionNode.id,
                    to: newNode.node.id,
                    label: "RECOMMENDED",
                });
                setAllEdges((oldArray) => [...oldArray, fromChampionNode.id + newNode.node.id]);
            }
        }

    }, [newNode]) // eslint-disable-line react-hooks/exhaustive-deps

    async function addItemBasic(itemId) {
        const rels = await client.query({
            query: GET_ITEM_BASIC,
            variables: { name: itemId },
        });
        if (rels.data && rels.data.itemBasics) {

            rels.data.itemBasics.map((el) => {
                var pair = {"group": "ItemBasic"}
                var addedPair = {...el, ...pair};
                setFromChampionNode(itemId)
                setNode(addedPair)
                return null;
                }
            );
        }        
    };

    async function addItemCombined(itemId) {
        const rels = await client.query({
            query: GET_ITEM_COMBINED,
            variables: { name: itemId },
        });
        if (rels.data && rels.data.itemCombineds) {

            rels.data.itemCombineds.map((el) => {
                setNode(el)
                return null;
                }
            );
        }        
    };

    async function buildTeam(teamId, teamObject) {
    
        if (edgeObject && nodeObject) {
            edgeObject.clear();
            nodeObject.clear();
        }
        setUniqueChampions([]);
        setHealth(0)
        setMana(0)
        setArmor(0)
        setMr(0)
        setDps(0)
        setAtkSpd(0)
        setDamage(0)
        setRange(0)        
        setAllNodes([]);
        setAllEdges([]);
        setAllSynergies([]);
        setHexImage1({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage2({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage3({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage4({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage5({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage6({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage7({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage8({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage9({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage10({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage11({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage12({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage13({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage14({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage15({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage16({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage17({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage18({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage19({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage20({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage21({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage22({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage23({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage24({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage25({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage26({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage27({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
        setHexImage28({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})

        await Promise.all(
          teamObject.edges.map(async (champ) => {
            setAllNodes(x => []);
            setAllEdges([]);
            await addCharacterFromTeam(champ.node.id, teamId);
          })
        );
      }

      useEffect(() => {
        if (location.state) {
            buildTeam(location.state.teamId, location.state.teamData)
        }
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // given a champion id, a position and a star add the champion to the board, add their stars
    async function addChampionWithPosition(championId, position, star) {
        const rels = await client.query({
            query: EXPLORATION_QUERY,
            variables: { name: championId },
        });
        if (rels.data && rels.data.champions) {
            rels.data.champions.map((el) => {
                setFromChampionNode(el)
                let champWithStar = Object.assign({}, el, {star:star})
                champWithStar.memberOfConnection = {edges: [{position: position, level: star}]};                
                setNode(champWithStar)
                if (!allNodes.includes(el.id)) {
                    let synergyNodes = rels.data.champions[0].synergies;
                    synergyNodes.map((sy) => {
                        setNode(sy)
                    return null;
                    });

                    // should only add items that are related to the team or null
                    let combinedItems = rels.data.champions[0].recommendedItemsConnection.edges;
                    combinedItems.map((item) => {
                        setNode(item)
                    return null
                    });                      
                }
                return null;
                }
            );
        }            
    }

    function teamToJSON() {
        const team = {
            champions: [],
          };
        for (const [key, value] of Object.entries(imagesList)) {
            if (value.imageSrc !== "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E") {
                const champion = {
                id: value.id.replace("champion#", ""),
                position: key.replace("hex", ""),
                level: value.star
              };
              team.champions.push(champion);
            }
          }
        return team;
    }
    
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    function customEncode(team) {
        const championNumberMap = new Map([...numberChampionMap].map(([k, v]) => [v, k]));
        const numberLetterMap = new Map([...letterNumberMap].map(([k, v]) => [v, k]));

        var encoded = ""
        team.champions.map(champion => {
            var champToInt = championNumberMap.get(champion.id)
            var positionToLetter = numberLetterMap.get(champion.position)
            var levelToLetter = numberLetterMap.get(champion.level.toString())
            encoded += champToInt + positionToLetter + levelToLetter;
            return null;
        })
        return encoded
    }

    function customDecode(encoded) {
        const team = {
            champions: [],
          };
        for (let i = 0; i < encoded.length; i += 4) {
            const id = "champion#".concat(numberChampionMap.get(encoded.substr(i, 2)));
            const position = letterNumberMap.get(encoded.substr(i + 2, 1));
            const level = parseInt(letterNumberMap.get(encoded.substr(i + 3, 1)),10);
            team.champions.push({ id, position, level });
        }
        return team;
    }
    
    const handleShareClick = () => {
        var team = teamToJSON();
        const customEncoded = customEncode(team);
        const shareUrl = `${window.location.origin}/team-builder?team=${customEncoded}`;
        copy(shareUrl);
        setCopyAlert(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setCopyAlert(false);
        setCreateAccountAlert(false);
        setTooManyChampsAlert(false);
        setTooFewChampsAlert(false);
      };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const encodedTeamExists = params.get('team');
        if (encodedTeamExists) {
            const teamEncoded = window.location.search.split('team=')[1];
            const teamDecoded = customDecode(teamEncoded);
            teamDecoded.champions.map(champion => {
                addChampionWithPosition(champion.id, champion.position, champion.level)
                return null;
            })
        }
      }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const {loading, error, data} = useQuery(GET_ALL_CHAMPIONS)

    const ChampionDrawer = () => {

        if (error) {
            console.log(error)
        }

        if (loading) return (     
            <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >   
            <Box sx={{ m: 2 }} justifyContent="center" alignItems="center">
                <WhiteCircularProgress/>
            </Box>
            </div> 
        )
        const allChampions = data.champions.slice().sort((a,b) => {
            return a.cost - b.cost;
        })
        return (
            <Box sx={{  alignSelf: "center", backgroundColor:"#121212"}}>
                <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                paddingBottom: '1rem',
                paddingTop: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '94%',
                    }}>
                        <WhiteTextField
                            id="outlined-basic"
                            label="Search Champion or Synergy"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(event) => setSearchQuery(event.target.value)}
                            inputRef={inputRef}
                            autoFocus={shouldAutoFocus}
                            onClick={handleClick}
                            onBlur={handleBlur}
                            inputProps={{ style: {color: 'white', fontFamily:"Outfit" } }}
                            InputLabelProps={{
                                sx: {
                                    fontFamily:"Outfit",
                                color: 'white', // set the initial color of the label
                                '&.Mui-focused': {
                                    color: 'white', // set the color of the label when the TextField is focused
                                },
                                },
                            }}
                            sx={{
                                width: "100%",
                            }} 
                        />
                    </Box>
                </Box>
                    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ maxWidth: "600px", marginBottom: "16px" }}>
                    {allChampions.map((champion) => (
                        <Grid key={champion.name} item style={{ margin: "0x" }}>
                        <Box
                            sx={{
                            '&:hover': {
                                opacity: [0.9, 0.8, 0.7],
                            },
                            }}
                        >
                            <Tooltip title={champion.name} arrow>
                            <img
                                id="characterList"
                                src={champion.imageSrc}
                                alt={champion.name}
                                onClick={() => setNodeToAddFromClick(champion)}
                                width="40"
                                height="40"
                                style={{
                                opacity: searchQuery
                                    ? champion.name.toLowerCase().includes(searchQuery.toLowerCase()) || champion.synergies.some((synergy) => synergy.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                    ? 1
                                    : 0.2
                                    : 1,
                                border: `${champion.cost === 1 ? "2px solid grey" : champion.cost === 2 ? "2px solid green" : champion.cost === 3 ? "2px solid rgba(79, 163, 255, 0.8)" : champion.cost === 4 ? "2px solid purple" :  champion.cost === 5 ? "2px solid gold" : "2px solid black"}`,
                                margin: "4px", // increase spacing between each img element
                                }}
                                onDragStart={(event) => handleDragStart(event, champion)}
                                draggable
                            />
                            </Tooltip>
                        </Box>
                        </Grid>
                    ))}
                    </Grid>
                </Box>    
    )}

    // Expand synergies in the popup
    async function expandSynergies() {
        // Query all champions of a given synergy
        const rels = await client.query({
            query: CHAMPION_BY_SYNERGY_QUERY,
            variables: { synergy: currentNode },
        });
        if (rels.data && rels.data.champions) {
            // For each champion
            rels.data.champions.map((el) => {
                var onBoard = false
                for(let i = 0; i < Object.values(imagesList).length; i++) {
                    let obj = Object.values(Object.values(imagesList)[i]);
                    if (obj[1] === el.id) {
                        onBoard = true
                        break
                    }
                }
                if (onBoard === false) {
                    setNodeToAddFromClick(el)
                }
                return null;
            });
            return null;
        }

    };
    // Expand synergies in the popup
    async function expandRecommendedFor() {
        // Query all champions of a given synergy
        const rels = await client.query({
            query: CHAMPION_BY_RECOMMENDED_FOR,
            variables: { recommendedFor: currentNode },
        });
        if (rels.data) {
            // For each champion
            rels.data.itemCombineds[0].recommendedFor.map((el) => {
                addCharacter(el.id)
                return null;
            });
        }
    };

    // Expand the combined items that are built by the selected basic item
    async function expandBuilds() {
        let allNodesTemp = allNodes
        // Query all champions of a given synergy
        const rels = await client.query({
            query: COMBINED_ITEM_BY_BASIC_ITEM,
            variables: { basicItem: currentNode },
        });

        if (rels.data) {
            // For each champion
            rels.data.itemBasics[0].builds.map((el) => {
                // If the champion is not on the network
                if (!allNodesTemp.includes(el.id)) {
                    // add the combined item
                    nodeObject.add({ id: el.id, label: el.name, group: "ItemCombined", shape: "image",   image: el.imageSrc });
                    // setAllNodes((oldArray) => [...oldArray, el.id]);
                    allNodesTemp = allNodesTemp.concat(el.id)
                    // add the edge from the basic item to the newly added combined item
                    if (!allEdges.includes(el.name + currentNode)) {
                        edgeObject.add({
                            from: currentNode,
                            to: el.id,
                            label: "BUILDS",
                        });
                        setAllEdges((oldArray) => [...oldArray, el.name + currentNode]);
                    }
                    let uniq = [...new Set(allNodesTemp)];
                    setAllNodes((oldArray) => [...oldArray, ...uniq])
                }
                return null;
            });
        }
    };

    async function expandBuiltBy() {
        let allNodesTemp = allNodes
        // Query all champions of a given synergy
        const rels = await client.query({
            query: BASIC_ITEM_BY_COMBINED_ITEM,
            variables: { combinedItem: currentNode },
        });
        if (rels.data) {
            // For each item
            rels.data.itemCombineds[0].builtBy.map((el) => {
                // If the champion is not on the network
                if (!allNodesTemp.includes(el.id)) {
                    // add the combined item
                    nodeObject.add({ hidden: hideItemNodes, id: el.id, label: el.name, group: "ItemBasic", shape: "image",   image: el.imageSrc  });
                    allNodesTemp = allNodesTemp.concat(el.id)
                    // add the edge from the basic item to the newly added combined item
                    if (!allEdges.includes(el.name + currentNode)) {
                        edgeObject.add({
                            from: el.id,
                            to: currentNode,
                            label: "BUILDS",
                        });
                        setAllEdges((oldArray) => [...oldArray, currentNode + el.name]);
                    }
                    let uniq = [...new Set(allNodesTemp)];
                    setAllNodes((oldArray) => [...oldArray, ...uniq])
                }
                return null;
            });
        }
    };

    // Visualization options for VisJS
    const options = {
        autoResize: true,
        layout: {improvedLayout: true },
        interaction: {hover: true, zoomView: false},
        nodes: {
            shape: "dot",
            font:  { size: 16, color: "#ffffff",   face: 'Outfit'}
        },
        edges: {
            color: 'fffffa',
            selectionWidth: 4,
            length: 200,
            font: { size: 0, color: "#ffffff", strokeWidth: 0, face: 'Outfit'}
        },
            physics:{
                enabled: true,
                barnesHut: {
                gravitationalConstant: -5000,
                centralGravity: 0.06,
                springLength: 20000,
                springConstant: 0.08,
                damping: 0.3,
                avoidOverlap: 1
            }
        }

    };

    useEffect(() => {
        let nodesToDelete = []
        let edgesToDelete = []
        if (nodeToDelete !== undefined) {
            nodeObject.remove(nodeToDelete.id);
            nodesToDelete.push(nodeToDelete.id)
            edgesToDelete.push()
            if (uniqueChampions.includes(nodeToDelete.id)){
                setUniqueChampions(uniqueChampions.filter((x)=>x!==nodeToDelete.id))
            }
            var newArray = Object.values(edgeObject._data).filter(function (el) {
                return el.from === nodeToDelete.id
              });
            var edgeIdsToDelete = Object.values(newArray).map(x => x.id)
            var itemsToCheck = Object.values(newArray).map(x => x.to).filter( function (x) { return  x.includes("item")})
            itemsToCheck.map((id) => {
                var edgeCount = Object.values(edgeObject._data).filter(function (el) {
                    return el.to === id
                  }).length
                  if (edgeCount === 1) {
                    nodeObject.remove(id)
                    nodesToDelete.push(id)
                    edgesToDelete.push(nodeToDelete.id.concat(id))
                  }
                  return null
            })

            var synergiesToCheck = Object.values(newArray).map(x => x.to).filter( function (x) { return  x.includes("synergy")})
            var updatedAllSynergies = allSynergies.map((synergy) => {
                if (synergiesToCheck.includes(synergy.id)) { 
                    var updatedSynergyCount = synergy.count - 1
                    var numberOfTiers = synergy.counts.length
                    var bgColour = "grey"
                    var tier = 5
                    var tick = 1
                    if(updatedSynergyCount < 1) {
                        return null;
                    }
                    else if (numberOfTiers === 1) {
                        if (synergy.counts[0] === 1) {
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                            return {id: synergy.id, name: synergy.name, imageSrc: synergy.imageSrc,  count: updatedSynergyCount, counts: synergy.counts, backgroundColour: "gold", tier: 2}
                        }
                        else {
                            tick = 1
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-grey")}`], size: 30 })
                            synergy.counts.map((count) => {
                                if (updatedSynergyCount >= count && tick === 1) {
                                    bgColour = "gold"
                                    nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                                    tier = 4
                                }
                                tick = tick + 1
                                return null;
                            })
                            return {id: synergy.id, name: synergy.name, imageSrc: synergy.imageSrc,  count: updatedSynergyCount, counts: synergy.counts, backgroundColour: bgColour, tier: tier}
                        }
                      }
                    else if (numberOfTiers === 2) {
                        tick = 1
                        nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-grey")}`], size: 30 })
                        synergy.counts.map((count) => {
                            if (updatedSynergyCount >= count && tick === 1) {
                                bgColour = "rgba(206, 108, 0)"
                                nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
                                tier = 4
                            }
                            else if  (updatedSynergyCount >= count && tick === 2) {
                                bgColour = "gold"
                                nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-gold")}`], size: 50 })
                                tier = 2
                            }
                            tick = tick + 1
                            return null;
                        })
                        return {id: synergy.id, name: synergy.name, imageSrc: synergy.imageSrc,  count: updatedSynergyCount, counts: synergy.counts, backgroundColour: bgColour, tier: tier}
                      }
                    else if (numberOfTiers === 3) {
                        tick = 1
                        nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-grey")}`], size: 30 })
                        synergy.counts.map((count) => {
                            if (updatedSynergyCount >= count && tick === 1) {
                                tier = 4
                                bgColour = "rgba(206, 108, 0)"
                                nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
                            }
                            else if (updatedSynergyCount >= count && tick === 2) {
                                tier = 3
                                bgColour = "silver"
                                nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-silver")}`], size: 40 })
                            }
                            else if (updatedSynergyCount >= count && tick === 3) {
                                bgColour = "gold"
                                tier = 2
                                nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-gold")}`], size:50 })
                            }
                            tick = tick + 1
                            return null;
                        })
                        return {id: synergy.id, name: synergy.name, imageSrc: synergy.imageSrc,  count: updatedSynergyCount, counts: synergy.counts, backgroundColour: bgColour, tier: tier}
                    }
                    else if (numberOfTiers === 4) {
                        tick = 1
                        nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-grey")}`], size: 30 })
                        synergy.counts.map((count) => {
                          if (updatedSynergyCount >= count && tick === 1) {
                            bgColour = "rgba(206, 108, 0)"
                            tier = 4
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-bronze")}`], size: 40 })
    
                          }
                          else if  (updatedSynergyCount >= count && tick === 2) {
                            bgColour = "silver"
                            tier = 3
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-silver")}`], size:40 })
    
                          }
                          else if  (updatedSynergyCount >= count && tick === 3) {
                            bgColour = "gold"
                            tier = 2
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-gold")}`], size:50  })
    
                          }
                          else if (updatedSynergyCount >= count && tick === 4) {
                            bgColour = "green"
                            tier = 1
                            nodeObject.update({id: synergy.id, image: synergyList[`${synergy.id.replace("synergy#", "").concat("-green")}`], size:60 })
    
                          }
                        tick = tick + 1
                        return null
                        })
                        return {id: synergy.id, name: synergy.name, imageSrc: synergy.imageSrc,  count: updatedSynergyCount, counts: synergy.counts, backgroundColour: bgColour, tier: tier}
                    }
                }
                else {
                    return { backgroundColour: synergy.backgroundColour, count: synergy.count, counts: synergy.counts,id: synergy.id,imageSrc: synergy.imageSrc,name: synergy.name,tier: synergy.tier}
                }
                return null;
            }).filter((value) => value !== null);;
            setAllSynergies(updatedAllSynergies)
            var synergiesToDelete = synergiesToCheck.filter((synergy) => {
                return !updatedAllSynergies.map(synergy => synergy.id).includes(synergy);
              });

            synergiesToDelete.map(id => nodesToDelete.push(id))
            edgeIdsToDelete.map(id => edgeObject.remove(id))
            var remainingNodeIds = Object.values(nodeObject._data).map(x => Object.values(x)[0])
            var championIds = Object.values(Object.values(nodeObject._data).filter(function (el) {
                return (el.group === "Champion")
            })).map(x => Object.values(x)[0])
            var idsToKeep = remainingNodeIds.map( (x) => {
                    var toKeep =  Object.values(edgeObject._data).filter(function (el) {
                        return (el.to === x)
                    });     
                    if (toKeep.length > 0) {
                        return Object.values(toKeep)[0].to
                    }
                    else {
                        return null
                    }
                }
            ).join(championIds)
            var idsToRemove = remainingNodeIds.filter(value => !idsToKeep.includes(value));
            idsToRemove.map(id => {
                nodeObject.remove(id)
                edgesToDelete.push(nodeToDelete.id.concat(id))
                return null;
            })
        }
        const updatedNodes = allNodes.filter(node => !nodesToDelete.includes(node));
        setAllNodes(updatedNodes);
        const updatedEdges = allNodes.filter(node => !edgesToDelete.includes(node));
        setAllEdges(updatedEdges);
    }, [nodeToDelete]) // eslint-disable-line react-hooks/exhaustive-deps

    const [width, setWidth] = useState(window.innerWidth);
    if (width > 9999999) { // no unused vars
        console.log(width)
    }
    const matches = useMediaQuery(`(max-width:${600}px)`);
    
    var explorationHeight = 0
    if (!matches) {
        explorationHeight = "calc(100dvh - 128px)"
    }
    else {
        explorationHeight = "calc(100dvh - 120px)"
    }

    var sidebarWidth = "0px"
    if (!matches) {
        sidebarWidth = "350px"
    }
    else {
        sidebarWidth = "0px"
    }


    var graphWidth = `calc(100% - ${(parseInt(sidebarWidth.replace("px", "")) * 2).toString()}px)`;

    var hexagonLWidth = 0
    var hexagonLHeight = 0
    var hexagonSWidth = 0
    var hexagonSHeight = 0
    var gridWidth = 0
    if (!matches) {
        hexagonLHeight = "60px"
        hexagonLWidth = "51.69px"
        hexagonSHeight = "54px"
        hexagonSWidth = "45.69px"
        gridWidth = "400px"
    }
    else {
        hexagonLHeight = "50px"
        hexagonLWidth = "43.3px"
        hexagonSHeight = "44px"
        hexagonSWidth = "37.3px"
        gridWidth = "340px"
    }

    var hexRelShift = String(parseInt(hexagonLHeight.replace("px", ""))/4).concat("px")
    var hexTopShift = String(parseInt(hexagonLHeight.replace("px", ""))/2.5).concat("px")

    var heightOfBoard = String(parseInt(hexagonLHeight.replace("px", ""))*3.6).concat("px")
    var heightOfGraph = "calc(100% - ".concat(heightOfBoard).concat(")")

    const [hideBoard, setHideBoard] = useState({ heightBoard: heightOfBoard , heightGraph: heightOfGraph , display: "block", checked: true })

    function addTeamStats(input) {
        let star = input.star
        if (star === 1) {
            setHealth((oldHealth) => (oldHealth + input.health1))
            setMana((oldMana) => (oldMana + input.mana1))
            setArmor((oldArmor) => (oldArmor + input.armor1))
            setMr((oldMr) => (oldMr + input.mr1))
            setDps((oldDps) => (oldDps + input.dps1))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd + input.atkSpd1))
            setDamage((oldDamage) => (oldDamage + input.damage1))
            setRange((oldRange) => (oldRange + input.range1))  
        }
        else if (star === 2) {
            setHealth((oldHealth) => (oldHealth + input.health2))
            setMana((oldMana) => (oldMana + input.mana2))
            setArmor((oldArmor) => (oldArmor + input.armor2))
            setMr((oldMr) => (oldMr + input.mr2))
            setDps((oldDps) => (oldDps + input.dps2))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd + input.atkSpd2))
            setDamage((oldDamage) => (oldDamage + input.damage2))
            setRange((oldRange) => (oldRange + input.range2))  
        }
        else if (star === 3) {
            setHealth((oldHealth) => (oldHealth + input.health3))
            setMana((oldMana) => (oldMana + input.mana3))
            setArmor((oldArmor) => (oldArmor + input.armor3))
            setMr((oldMr) => (oldMr + input.mr3))
            setDps((oldDps) => (oldDps + input.dps3))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd + input.atkSpd3))
            setDamage((oldDamage) => (oldDamage + input.damage3))
            setRange((oldRange) => (oldRange + input.range3))  
        }      
    }

    function removeTeamStats(input, from) {
        if (from === 1) {
            setHealth((oldHealth) => (oldHealth - input.health1))
            setMana((oldMana) => (oldMana - input.mana1))
            setArmor((oldArmor) => (oldArmor - input.armor1))
            setMr((oldMr) => (oldMr - input.mr1))
            setDps((oldDps) => (oldDps - input.dps1))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd - input.atkSpd1))
            setDamage((oldDamage) => (oldDamage - input.damage1))
            setRange((oldRange) => (oldRange - input.range1))  
        }
        else if (from === 2) {
            setHealth((oldHealth) => (oldHealth - input.health2))
            setMana((oldMana) => (oldMana - input.mana2))
            setArmor((oldArmor) => (oldArmor - input.armor2))
            setMr((oldMr) => (oldMr - input.mr2))
            setDps((oldDps) => (oldDps - input.dps2))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd - input.atkSpd2))
            setDamage((oldDamage) => (oldDamage - input.damage2))
            setRange((oldRange) => (oldRange - input.range2))  
        }
        else if (from === 3) {
            setHealth((oldHealth) => (oldHealth - input.health3))
            setMana((oldMana) => (oldMana - input.mana3))
            setArmor((oldArmor) => (oldArmor - input.armor3))
            setMr((oldMr) => (oldMr - input.mr3))
            setDps((oldDps) => (oldDps - input.dps3))
            setAtkSpd((oldAtkSpd) => (oldAtkSpd - input.atkSpd3))
            setDamage((oldDamage) => (oldDamage - input.damage3))
            setRange((oldRange) => (oldRange - input.range3))  
        }        
    }


    useEffect(() => {
        const handleWindowResize = () => {
          setWidth(window.innerWidth);
        };    
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    function handleDragStart(e, champion) {
        e.dataTransfer.setData("text", JSON.stringify({
            elementId: e.target.id,
            name: champion.name,
            id: champion.id,
            star: champion.star,
            imageSrc: champion.imageSrc,
            cost: champion.cost,
            health1: champion.health1,
            mana1: champion.mana1,
            armor1: champion.armor1,
            mr1: champion.mr1,
            dps1: champion.dps1,
            atkSpd1: champion.atkSpd1,
            range1: champion.range1,
            damage1: champion.damage1,
            health2: champion.health2,
            mana2: champion.mana2,
            armor2: champion.armor2,
            mr2: champion.mr2,
            dps2: champion.dps2,
            atkSpd2: champion.atkSpd2,
            range2: champion.range2,
            damage2: champion.damage2,
            health3: champion.health3,
            mana3: champion.mana3,
            armor3: champion.armor3,
            mr3: champion.mr3,
            dps3: champion.dps3,
            atkSpd3: champion.atkSpd3,
            range3: champion.range3,
            damage3: champion.damage3
        }));
    };
      
    function handleDragOver(e) {
        e.preventDefault();
    }
    var setImagesList = {
        "hex1": (data) => setHexImage1(data),
        "hex2": (data) => setHexImage2(data),
        "hex3": (data) => setHexImage3(data),
        "hex4": (data) => setHexImage4(data),
        "hex5": (data) => setHexImage5(data),
        "hex6": (data) => setHexImage6(data),
        "hex7": (data) => setHexImage7(data),
        "hex8": (data) => setHexImage8(data),
        "hex9": (data) => setHexImage9(data),
        "hex10": (data) => setHexImage10(data),
        "hex11": (data) => setHexImage11(data),
        "hex12": (data) => setHexImage12(data),
        "hex13": (data) => setHexImage13(data),
        "hex14": (data) => setHexImage14(data),
        "hex15": (data) => setHexImage15(data),
        "hex16": (data) => setHexImage16(data),
        "hex17": (data) => setHexImage17(data),
        "hex18": (data) => setHexImage18(data),
        "hex19": (data) => setHexImage19(data),
        "hex20": (data) => setHexImage20(data),
        "hex21": (data) => setHexImage21(data),
        "hex22": (data) => setHexImage22(data),
        "hex23": (data) => setHexImage23(data),
        "hex24": (data) => setHexImage24(data),
        "hex25": (data) => setHexImage25(data),
        "hex26": (data) => setHexImage26(data),
        "hex27": (data) => setHexImage27(data),
        "hex28": (data) => setHexImage28(data),
    };
    function handleDrop(e) {
        e.stopPropagation()
        e.preventDefault();
        const data = JSON.parse(e.dataTransfer.getData("text"));
        const droppedFrom = data.elementId
        const droppedOn = e.target.id
        if (droppedOn && droppedOn.includes("hex") && !droppedFrom.includes("hex")) {
            const tempImage = imagesList[droppedOn]
            addTeamStats(data)
            addCharacter(data.id)
            if (droppedOn.includes("hex") ){
                setImagesList[droppedOn](data)
            }
            if (droppedFrom.includes("hex")) {
                setImagesList[droppedFrom](tempImage)
            }
        }
        else if (droppedOn && droppedOn.includes("hex") && droppedFrom.includes("hex")) {
            const tempImage = imagesList[droppedOn]
            if (droppedOn.includes("hex") ){
                setImagesList[droppedOn](data)
            }
            if (droppedFrom.includes("hex")) {
                setImagesList[droppedFrom](tempImage)
            }
        }
        else if (droppedFrom.includes("hex")) {
            var championCount = 0
            for(let i = 0; i < Object.values(imagesList).length; i++) {
                let obj = Object.values(Object.values(imagesList)[i]);
                if (obj[1] === JSON.parse(e.dataTransfer.getData("text")).imageSrc) {
                    championCount = championCount + 1
                    if (championCount > 1) {
                        break
                    }
                }
            }
            if (championCount > 1) {
                setImagesList[droppedFrom]({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
                removeTeamStats(data, data.star)
            }
            else {
                setImagesList[droppedFrom]({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
                removeTeamStats(data, data.star)
                setNodeToDelete(data)
            }
        }
    }

    const [hexImage1, setHexImage1] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage2, setHexImage2] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage3, setHexImage3] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage4, setHexImage4] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage5, setHexImage5] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage6, setHexImage6] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage7, setHexImage7] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage8, setHexImage8] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage9, setHexImage9] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage10, setHexImage10] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage11, setHexImage11] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage12, setHexImage12] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage13, setHexImage13] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage14, setHexImage14] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage15, setHexImage15] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage16, setHexImage16] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage17, setHexImage17] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage18, setHexImage18] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage19, setHexImage19] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage20, setHexImage20] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage21, setHexImage21] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage22, setHexImage22] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage23, setHexImage23] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage24, setHexImage24] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage25, setHexImage25] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage26, setHexImage26] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage27, setHexImage27] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});
    const [hexImage28, setHexImage28] = useState({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null});

    var imagesList = {
        "hex1": hexImage1,
        "hex2": hexImage2,
        "hex3": hexImage3,
        "hex4": hexImage4,
        "hex5": hexImage5,
        "hex6": hexImage6,
        "hex7": hexImage7,
        "hex8": hexImage8,
        "hex9": hexImage9,
        "hex10": hexImage10,
        "hex11": hexImage11,
        "hex12": hexImage12,
        "hex13": hexImage13,
        "hex14": hexImage14,
        "hex15": hexImage15,
        "hex16": hexImage16,
        "hex17": hexImage17,
        "hex18": hexImage18,
        "hex19": hexImage19,
        "hex20": hexImage20,
        "hex21": hexImage21,
        "hex22": hexImage22,
        "hex23": hexImage23,
        "hex24": hexImage24,
        "hex25": hexImage25,
        "hex26": hexImage26,
        "hex27": hexImage27,
        "hex28": hexImage28,

    }

    const TeamRow = ({ index, style, data }) => {
        const team = data[index];
        return (
          <div style={style}>
            <div
              style={{
                backgroundColor: '#121212',
                height: 'auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', width: '100%'}}>
                <Typography variant="h6" align="left" style={{ fontWeight: 'normal', marginTop: '0', marginBottom: '-5px', color: 'white', textAlign: 'left', marginLeft: "10px", flexShrink: 1 }}>
                  {team.name}
                </Typography>
                <Button variant="contained" endIcon={<AddIcon />} onClick={() => {buildTeam(team.id, team.membersConnection)}} style={{ marginRight: "20px", flexGrow: 1, maxWidth: "100px", minWidth: "100px", backgroundColor:"white"}}>
                  Build
                </Button>
              </div>
              {team.top4Rate 
                    ? <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <Typography variant="subtitle1" align="left" style={{ marginBottom: '0px', color: 'white', marginLeft: '10px' }}>
                        Win Rate: {team.winRate} | Top 4 Rate: {team.top4Rate}
                        </Typography>
                    </div>
                    : null
                }
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '5px', marginBottom: '5px', width: '100%', boxSizing: 'border-box' }}>
                {team.membersConnection.edges.slice().sort((a,b) => {
                                                        return a.node.cost - b.node.cost;
                                                    }).map(({node, position}) => (
                  <Tooltip title={node.name} key={`tooltip${team.id}${node.id}${position}`} arrow>
                    <img
                      key={`${team.id}${node.id}${position}`}
                      src={node.imageSrc}
                      alt={node.name}
                      style={{width: '30px', height: '30px', margin: '2px' , border: `${node.cost === 1 ? "2px solid grey" : node.cost === 2 ? "2px solid green" : node.cost === 3 ? "2px solid rgba(79, 163, 255, 0.8)" : node.cost === 4 ? "2px solid purple" :  node.cost === 5 ? "2px solid gold" : "2px solid black"}` }}
                    />
                  </Tooltip>
                ))}
              </div> 
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
              <StyledDivider />
            </div>
          </div>
        );
      };
      

    useEffect(() => {
        if (nodeToAddFromClick.id !== null) {
            let championToAddWithStar = Object.assign({}, nodeToAddFromClick, {star:1})
            addTeamStats(championToAddWithStar)
            addCharacter(nodeToAddFromClick.id)
            for(let i = 0; i < Object.values(imagesList).length; i++) {
                let obj = Object.values(Object.values(imagesList)[i]);
                if (obj[0] === "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E") {
                    var hex = "hex".concat(i+1)
                    setImagesList[hex]({
                        name: nodeToAddFromClick.name,
                        id: nodeToAddFromClick.id,
                        star: 1,
                        imageSrc: nodeToAddFromClick.imageSrc,
                        cost: nodeToAddFromClick.cost,
                        health1: nodeToAddFromClick.health1,
                        mana1: nodeToAddFromClick.mana1,
                        armor1: nodeToAddFromClick.armor1,
                        mr1: nodeToAddFromClick.mr1,
                        dps1: nodeToAddFromClick.dps1,
                        atkSpd1: nodeToAddFromClick.atkSpd1,
                        range1: nodeToAddFromClick.range1,
                        damage1: nodeToAddFromClick.damage1,
                        health2: nodeToAddFromClick.health2,
                        mana2: nodeToAddFromClick.mana2,
                        armor2: nodeToAddFromClick.armor2,
                        mr2: nodeToAddFromClick.mr2,
                        dps2: nodeToAddFromClick.dps2,
                        atkSpd2: nodeToAddFromClick.atkSpd2,
                        range2: nodeToAddFromClick.range2,
                        damage2: nodeToAddFromClick.damage2,
                        health3: nodeToAddFromClick.health3,
                        mana3: nodeToAddFromClick.mana3,
                        armor3: nodeToAddFromClick.armor3,
                        mr3: nodeToAddFromClick.mr3,
                        dps3: nodeToAddFromClick.dps3,
                        atkSpd3: nodeToAddFromClick.atkSpd3,
                        range3: nodeToAddFromClick.range3,
                        damage3: nodeToAddFromClick.damage3
                    })
                    break
                }
            }
        }
    }, [nodeToAddFromClick]); // eslint-disable-line react-hooks/exhaustive-deps

    const [teamEncoding, setTeamEncoding] = useState("");

    const [fetchTeamViaEncoding, { data: teamViaEncodingData }] =
    useLazyQuery(GET_TEAM_WITH_ENCODING);
  
  useEffect(() => {
    if (user) {
        var team = teamToJSON();
        var teamEncoding = customEncode(team);
        setTeamEncoding(teamEncoding);    
        const handleTeamData = (data) => {
        if (data && data.teams.length > 0) {
            setTeamSaved(true);
        } else {
            setTeamSaved(false);
        }
        };
    
        // Fetch team data and provide the callback function
        fetchTeamViaEncoding({ variables: { username: user.email, encoding: teamEncoding } })
        .then(({ data }) => handleTeamData(data))
        .catch((error) => {
            // Handle any errors that occur during the data fetching
            console.error("Error fetching team data:", error);
        });
    }
  }, [hexImage1,hexImage2,hexImage3,hexImage4,hexImage5,hexImage6,hexImage7,hexImage8,hexImage9,hexImage10,hexImage11,hexImage12,hexImage13,hexImage14,hexImage15,hexImage16,hexImage17,hexImage18,hexImage19,hexImage20,hexImage21,hexImage22,hexImage23,hexImage24,hexImage25,hexImage26,hexImage27,hexImage28,teamViaEncodingData]); // eslint-disable-line react-hooks/exhaustive-deps

    function removeFromClick(input) {
        removeTeamStats(input)
        setNodeToDelete(input)
        setSelectedType()
        for(let i = 0; i < Object.values(imagesList).length; i++) {
            let obj = Object.values(Object.values(imagesList)[i]);
            if (obj[1] === input.id) {
                var hex = "hex".concat(i+1)
                setImagesList[hex]({imageSrc: "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E", cost: "0", id: null})
            }
        }
    }

    function toggleBoard() {
        if (hideBoard.display === "block") {
            setHideBoard({heightBoard: "0%", heightGraph: "100%", display: "none", checked: false})
        }
        else {
            setHideBoard({heightBoard: heightOfBoard, heightGraph: heightOfGraph, display: "block", checked: true})
        }
        return null;
    }

    function toggleItems() {
        var basicItemIds = Object.values(nodeObject._data).filter(function (el) {
            return el.group === "ItemBasic"
        }).map(x => x.id)

        var combinedItemIds = Object.values(nodeObject._data).filter(function (el) {
            return el.group === "ItemCombined"
        }).map(x => x.id)
        
        var edgeIds = Object.values(edgeObject._data).filter(function (el) {
            return (el.to.includes("item"))
        }).map(x => x.id)


        basicItemIds.map( id => nodeObject.update({id: id, hidden: !hideItemNodes}))
        combinedItemIds.map( id => nodeObject.update({id: id, hidden: !hideItemNodes}))
        edgeIds.map( id => edgeObject.update({id: id, hidden: !hideItemNodes}))

        if (hideItemNodes) {
            setHideItemNodes(false)
        }
        else {
            setHideItemNodes(true)
        }
        return null
    }

    var sortedTeams = []
    if (recommendedTeams) {
        const rankOrder = ['S', 'A', 'B', 'C', 'D', 'E', 'F'];
        if (recommendedTeams.data.teams[0]){
            if (!rankOrder.includes(recommendedTeams.data.teams[0].winRate)) {
                sortedTeams = [...recommendedTeams.data.teams].sort((a, b) => {
                return parseFloat(b.winRate) - parseFloat(a.winRate);
                }).map(team => ({
                ...team,
                winRate: `${parseFloat(team.winRate).toFixed(1)}%`
                }));
            } 
        else {
            sortedTeams = [...recommendedTeams.data.teams].sort((a, b) => {
              const aRank = rankOrder.indexOf(a.winRate.toUpperCase());
              const bRank = rankOrder.indexOf(b.winRate.toUpperCase());
              return aRank - bRank;
            });
        }
    }
}
      
    return (
        <div align = 'center' style = {{height: explorationHeight}} id = "external" onDragOver={handleDragOver} onDrop={handleDrop}  >
            <DocumentMeta {...meta} />
            {(['Champion Selection']).map((anchor) => (
                <div key={anchor}>
                    <div>
                        {!matches && (
                        <AppBar elevation={0} position='static' style={{ elevation: 0, background: '#121212' }}>
                            <Toolbar style={{height:"56px",minHeight:"100%",  padding:"0px"}} sx={{paddingLeft:"32px", paddingRight:"0"}}>
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: "20px" }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href='https://graphtft.com/'
              onClick={() => routeChange("/")}
              sx={{
                // fontWeight: 300,
                // letterSpacing: '.18rem',
                color: 'inherit',
                textDecoration: 'none',
                fontFamily:"Outfit",
                color:"white"
              }}
            >
              TFT Team Builder
            </Typography>
                            <Box sx={{marginLeft: '20px'}}>
                                <AnimatedButton
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                        onClick={toggleDrawer('bottom', true)}
                                        className={true ? 'button-animated' : ''}
                                        sx={{height:"36px", backgroundColor:"white"}}
                                    >
                                        Add Champion
                                </AnimatedButton>
                            </Box>
                            <Box sx={{marginLeft: '20px'}}>
                            <Button
                            variant="contained"
                            width="131px" // Set the constant width of the button
                            endIcon={
                                teamSaving ? (
                                <CircularProgress size={20} color="primary" /> // Show spinner while teamSaving is true
                                ) : teamSaved ? (
                                <FavoriteIcon sx={{ color: 'red' }} /> // Show heart icon if team is saved
                                ) : (
                                <FavoriteBorderIcon /> // Show empty heart icon if team is not saved
                                )
                            }
                            onClick={() => handleSaveTeam()}
                            sx={{ height: '36px', backgroundColor: 'white' }}
                            >
                            {teamSaved ? 'Team Saved' : 'Save Team'}
                            </Button>
                                <Snackbar open={openCreateAccount} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                        <div>
                                        <Alert onClose={handleSnackbarClose} severity="error">
                                            <Typography>

                                                <Link href="/signup" color="inherit">
                                                Sign up
                                                </Link>
                                                {' '} or {''}
                                                <Link href="/login" color="inherit">
                                                Log In
                                                </Link>
                                                {' '}
                                                to save teams.
                                            </Typography>
                                            </Alert>
                                        </div>
                                </Snackbar>
                                <Snackbar open={openTooManyChamps} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                        <div>
                                        <Alert onClose={handleSnackbarClose} severity="error">
                                            <Typography>
                                                Too many champions! The maximum is 9.
                                            </Typography>
                                            </Alert>
                                        </div>
                                </Snackbar>
                                <Snackbar open={openTooFewChamps} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                        <div>
                                        <Alert onClose={handleSnackbarClose} severity="error">
                                            <Typography>
                                                Too few champions! The minimum is 1.
                                            </Typography>
                                            </Alert>
                                        </div>
                                </Snackbar>
                            </Box>
                                    <Box sx={{marginLeft: '20px'}}>
                                        <Button
                                            variant="contained"
                                            endIcon={<ShareIcon />}
                                            onClick={() => handleShareClick()}
                                            sx={{ height: '36px', backgroundColor:"white" }}
                                        >
                                            Share Team
                                        </Button>
                                        <Snackbar open={openCopyAlert} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                        <div>
                                            <Alert onClose={handleSnackbarClose} severity="success">
                                            Link Copied!
                                            </Alert>
                                        </div>
                                    </Snackbar>
                                </Box>
                            </Box>
                            <MenuItem key={"hide-items"} sx={{color:"white"}}>
                                <MaterialUISwitch 
                                    defaultChecked={false}
                                      onChange={() => toggleItems()} />
                                <WhiteTextTypography>Show Items</WhiteTextTypography>
                            </MenuItem>
                            <MenuItem key={"hide-board"}  sx={{color:"white"}}>
                                <MaterialUISwitch 
                                    checked={hideBoard.checked}
                                    onChange={() => toggleBoard()} />
                                <Typography>Show Board</Typography>
                            </MenuItem>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box >

                                    <Box sx={{paddingRight: '20px'}}>
                                        <Button
                                            variant="contained"
                                            endIcon={<DoDisturbIcon />}
                                            onClick={clearGraph()}
                                            sx={{ height: '36px', backgroundColor:"white"}}
                                        >
                                            Clear
                                        </Button>
                                    </Box> 
                                </Box>                      
                            </Toolbar>
                        </AppBar>
                        )}
                    </div>
                    <div style={{zIndex:"1000"}}  >
                        {matches ? (
                            <StyledBottomNavigation
                                value={valueX}
                                onChange={(event, newValue) => {
                                    setValueX(newValue);
                                }}
                                showLabels
                            >
                                <BottomNavigationAction value={0} sx={{color: '#FFFFFF'}} label="Add" icon={<AddIcon style={{ color: 'white' }} />} onClick={toggleDrawer('bottom', true)}/>
                                <BottomNavigationAction value={2} sx={{color: '#FFFFFF'}} label="Items" icon={<BuildIcon style={{ color: 'white' }} />} onClick={()=>toggleItems()}/>
                                <BottomNavigationAction value={3} sx={{color: '#FFFFFF'}} label="Board" icon={<HexagonIcon style={{ color: 'white', transform: "rotate(90deg)" }} />} onClick={()=>toggleBoard()}/>
                                <BottomNavigationAction value={4} sx={{color: '#FFFFFF'}} label="Clear" icon={<DoDisturbIcon style={{ color: 'white' }}/>} onClick={clearGraph()}/>
                            </StyledBottomNavigation>
                        ) : null}
                     </div>

                    <CustomDrawer
                                PaperProps={{
                                    sx: {
                                      backgroundColor: "transparent",
                                      color: "transparent"
                                    }
                                  }}
                        anchor='bottom'
                        open={state['bottom']}
                        onClose={toggleDrawer('bottom', false)}
                        sx = {{}}
                    >
                        <ChampionDrawer  style={{backgroundColor:"#121212"}}/>
                    </CustomDrawer>
                </div>
            ))}
            <div style={{ height: "100%", width: "100%"}}>
                <Grid
                    container
                    style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column"}}
                    >
                    { !matches && (
                    <Grid item sx={{height: "100vh", width: sidebarWidth, backgroundColor: "#121212", display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                        <Box sx={{backgroundColor:"#121212", borderRadius: "2px"}}>
                            <TableContainer component={Paper} sx={{backgroundColor:"#121212", borderRadius: "2px"}} elevation={0}>

                            <Box sx={{ textAlign: "left", paddingLeft:"20px", paddingBottom:"10px" }}>
                            <Typography
                                variant="h6"
                                align="left"
                                sx={{ color: "white", borderBottom: "4px solid #ffffff", display: "inline-block", textAlign: "left" }}
                            >
                                Team Statistics
                            </Typography>
                            </Box>

                            <Table sx={{ minWidth: 300 }} aria-label="simple table" size="small">
                                <TableBody>
                                {[
                                    { title: "Health", value: health },
                                    { title: "Mana", value: mana },
                                    { title: "Armor", value: armor },
                                    { title: "MR", value: mr },
                                    { title: "DPS", value: dps },
                                    { title: "Atk Spd", value: atkSpd },
                                    { title: "Damage", value: damage },
                                    { title: "Range", value: range },
                                ].map(({ title, value }) => (
                                    <TableRow key={title}>
                                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{title}</TableCell>
                                    <TableCell align="left" style={{color:"white", borderBottom: "1px solid #333"}}>{value}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Grid style={{height:"100%", display: "flex", flexDirection: "column", paddingTop: "10px"}}>
                    <Box sx={{ textAlign: "left", paddingLeft:"20px", paddingBottom:"10px" }}>
                            <Typography
                                variant="h6"
                                align="left"
                                sx={{ color: "white", borderBottom: "4px solid #ffffff", display: "inline-block", textAlign: "left" }}
                            >
                                Selected
                            </Typography>
                            </Box>                        <div style={{ overflowY: "auto", flexGrow: 1, height: "0"}}>
                        {selectedType=="champion" && characterData ? (
                            <SelectedChampion 
                                characterData={characterData} 
                                removeFromClick={removeFromClick} 
                                addItemCombined={addItemCombined} 
                            />
                        ) : selectedType=="synergy" && synergyData ? (
                            <SelectedSynergy 
                                synergyData={synergyData} 
                                expandSynergies={expandSynergies}
                                setNodeToAddFromClick={setNodeToAddFromClick}
                            />
                        ) : selectedType=="itemCombined" && itemCombinedData ? (
                            <SelectedItemCombined 
                                itemCombinedData={itemCombinedData} 
                                addItemBasic={addItemBasic}
                                setNodeToAddFromClick={setNodeToAddFromClick}
                                expandBuiltBy={expandBuiltBy}
                                expandRecommendedFor={expandRecommendedFor}
                            />
                        ) : selectedType=="itemBasic" && itemBasicData ? (
                            <SelectedItemBasic 
                                itemBasicData={itemBasicData} 
                                addItemCombined={addItemCombined}
                                expandBuilds={expandBuilds}
                            />
                        ) : (
                            <>
                                <InfoIcon style={{ paddingTop: "10px", color: "white" }} />
                                <Typography align='center' style={{ color: "white", width: "12vw" }}>
                                Select a champion, synergy or item in the network to view stats and meta information
                                </Typography>
                            </>
                        )}
                        </div>
                    </Grid>
                </Grid>
                )}
                    {hideBoard.checked ? (
                    <Grid container direction="row"
                            justifyContent="center" 
                            style={{    
                                height: hideBoard.heightBoard, 
                                width: graphWidth, 
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap:'nowrap',
                                backgroundColor: "#121212",}}
                        >
                            { !matches &&
                            <Grid item style={{maxHeight: `calc(${hideBoard.heightBoard} - 32px)`, paddingRight:"35px", width: "210px"}}>
                            <Typography variant= "h6" align='center' style={{color:"white"}}>Team Synergies</Typography>
                            <Box sx={{ maxHeight:'100%', backgroundColor:"#121212", flex: '1 1 auto', overflowY: 'auto', overflowX:"hidden",      }}>
                                <TableContainer component={Paper}  sx={{backgroundColor:"#121212", flexGrow: 1, overflowY: 'auto', overflowX:"hidden", wordWrap: 'break-word'}} elevation={0}>
                                    {(allSynergies.length === 0) ? (
                                                    <>
                                                    <InfoIcon style={{ paddingTop:"10px", color: "white" }} />
                                                    <Typography align='center' style={{color: "white" }}>
                                                    Add champions to view classes and origins
                                                    </Typography>
                                                </>
                                            ) : (
                                    <Grid style={{ display: "flex", position:"relative" }}>
                                    <Grid  sx={{ width: "100%", height: "100%", position: "relative", overflow: "hidden"}} >
                                        {allSynergies.sort((a,b) => {
                                            return a.tier - b.tier;
                                        }).map((x) => (
                                            <Grid item key={x.id} style={{ height:60, margin: 0,width: '100%'}}>
                                                    <Grid alignItems="center" container style={{ margin: 0,width: '100%', maxHeight:"60px"}}>
                                                        <Grid item  style={{ alignItem:"center", maxHeight:"60px"}}>
                                                          <HexagonIcon style={{verticalAlign:"middle", alignItems:"center", color: x.backgroundColour,width: 60,height: 60, paddingRight: 20}}></HexagonIcon>
                                                            <Box
                                                                m = {2.1}
                                                                ml ={5}
                                                                position="relative"
                                                                top="-60px"
                                                                bgcolor={`${x.backgroundColour}`}
                                                                style={{ 
                                                                    alignSelf:"left" ,
                                                                    alignItems:"left",
                                                                    alignContent:"left" ,
                                                                    width: 25,
                                                                    height:27
                                                                }}
                                                            >
                                                                <WhiteTextTypography style={{lineHeight:1.6}}>
                                                                    {x.count}
                                                                </WhiteTextTypography>
                                                            </Box>
                                                            <Box 
                                                                m = {2}
                                                                style={{alignSelf:"left" , alignItems:"left",alignContent:"left" ,width: 25,height: 25}}
                                                                position="relative"
                                                                left="-11px"
                                                                top="-120px"
                                                                component="img"
                                                                alt= {`${x.name}`}
                                                                src={`${x.imageSrc}`}
                                                            />
                                                        </Grid>
                                                        <Grid  item  style={{position: 'relative', alignItem:"center", top: "-0px", right: "-0px"}}>
                                                            <Box position="relative">
                                                            <div sx={{alignItems:"center",textAlign: 'left'}}>
                                                                <WhiteTextTypography style={{fontSize:"0.875rem"}} variant="body1" display="flex">{x.name}</WhiteTextTypography>
                                                                <WhiteTextTypography style={{fontSize:"0.875rem"}} variant="body2" display="flex">{x.counts.map((x)=>String(x)).join(" > ")}</WhiteTextTypography>
                                                            </div>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                {/* </div> */}
                                            </Grid>
                                        ))}
                                        
                                    </Grid>
                                    </Grid>
                                // </div> 
                            )}
                        </TableContainer>
                            </Box>
                        </Grid>
                        
                        }
                        <Grid item style={{height: hideBoard.heightBoard, maxHeight: hideBoard.heightBoard}}>
                            <Box sx={{ border: 0}} >
                             {/* Hexagon */}
                             <div style = {{position:"relative" ,right:"0px", height: "100%" }}>
                            <div style={{ maxWidth:"100%",  height: hexTopShift, display:"flex", position:"relative", justifyContent: "space-around" , paddingTop: "10px", width: gridWidth, right: hexRelShift}}>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        position: "relative",
                                        zIndex:"998",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage1.cost === "1" ? "grey" : hexImage1.cost === 2 ? "green" : hexImage1.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage1.cost === 4 ? "purple" :  hexImage1.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div style={{ position: "relative" }}>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage1} position={"hex1"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage1.imageSrc}
                                                id ="hex1"
                                                alt = "hex1"
                                                onDragStart={event => handleDragStart(event, hexImage1)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                draggable
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>

                                        </div>
                                    </div>
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage2.cost === "1" ? "grey" : hexImage2.cost === 2 ? "green" : hexImage2.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage2.cost === 4 ? "purple" :  hexImage2.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div style={{ position: "relative" }}>
                                            <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage2} position={"hex2"}>
                                                <div 
                                                style={{
                                                        position: "absolute",
                                                        top: "3px", /* eal to border thickness */
                                                        left: "3px", /* equal to border thickness */
                                                        width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                        height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "rgba(0, 0, 0, 0.82)",
                                                }}>
                                                    <img src={hexImage2.imageSrc}
                                                    id ="hex2"
                                                    alt="hex2"
                                                    onDragStart={event => handleDragStart(event, hexImage2)}
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    style={{
                                                        transform: "scale(1.1)",
                                                        position: "relative",
                                                        width: hexagonLHeight,
                                                        height: hexagonLHeight,
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "transparent",
                                                        left: "-8px"
                                                    }}
                                                    >
                                                    </img>
                                                </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage3.cost === "1" ? "grey" : hexImage3.cost === 2 ? "green" : hexImage3.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage3.cost === 4 ? "purple" :  hexImage3.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage3} position={"hex3"}>

                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage3.imageSrc}
                                                id ="hex3"
                                                alt="hex3"
                                                onDragStart={event => handleDragStart(event, hexImage3)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage4.cost === "1" ? "grey" : hexImage4.cost === 2 ? "green" : hexImage4.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage4.cost === 4 ? "purple" :  hexImage4.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage4} position={"hex4"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage4.imageSrc}
                                                id ="hex4"
                                                alt="hex4"
                                                onDragStart={event => handleDragStart(event, hexImage4)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                            </div>    
                            <div>
                                        <div 
                                        style={{  display: "inline-block",
                                            zIndex:"998",
                                            position: "relative",
                                            width: hexagonLWidth,
                                            height: hexagonLHeight, /* width * 0.866 */
                                            background:  `${
                                                hexImage5.cost === "1" ? "grey" : hexImage5.cost === 2 ? "green" : hexImage5.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage5.cost === 4 ? "purple" :  hexImage5.cost === 5 ? "gold" : "grey"}`,
                                            boxSizing: "border-box",
                                            "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                            "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                        }}>
                                            <div>
                                            <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage5} position={"hex5"}>

                                                <div 
                                                style={{
                                                        position: "absolute",
                                                        top: "3px", /* eal to border thickness */
                                                        left: "3px", /* equal to border thickness */
                                                        width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                        height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "rgba(0, 0, 0, 0.82)",
                                                }}>
                                                    <img src={hexImage5.imageSrc}
                                                    id ="hex5"
                                                    alt="hex5"
                                                    onDragStart={event => handleDragStart(event, hexImage5)}
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    style={{
                                                        transform: "scale(1.1)",
                                                        position: "relative",
                                                        width: hexagonLHeight,
                                                        height: hexagonLHeight,
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "transparent",
                                                        left: "-8px"
                                                    }}
                                                    >
                                                    </img>
                                                </div>
                                                </StarMenu>
                                            </div>
                                        </div>   
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage6.cost === "1" ? "grey" : hexImage6.cost === 2 ? "green" : hexImage6.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage6.cost === 4 ? "purple" :  hexImage6.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage6} position={"hex6"}>

                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage6.imageSrc}
                                                id ="hex6"
                                                alt="hex6"
                                                onDragStart={event => handleDragStart(event, hexImage6)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage7.cost === "1" ? "grey" : hexImage7.cost === 2 ? "green" : hexImage7.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage7.cost === 4 ? "purple" :  hexImage7.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage7} position={"hex7"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage7.imageSrc}
                                                id ="hex7"
                                                alt="hex7"
                                                onDragStart={event => handleDragStart(event, hexImage7)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            </div>
                            <div style={{maxWidth:"100%",height: hexTopShift, display:"flex", position:"relative", justifyContent: "space-around" , width: gridWidth, left: hexRelShift, paddingTop: hexTopShift}}>

                            <WhiteTextTypography style={{position: "absolute", left:"-48px", bottom: "-18px", transform: "rotate(270deg)", fontSize:"6", opacity: "0.3"}}>
                                Graph
                            </WhiteTextTypography>
                            <WhiteTextTypography style={{position: "absolute", left:"-22px", bottom:"-18px" , transform: "rotate(270deg)", fontSize:"6", opacity: "0.3"}}>
                                TFT
                            </WhiteTextTypography>

                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage8.cost === "1" ? "grey" : hexImage8.cost === 2 ? "green" : hexImage8.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage8.cost === 4 ? "purple" :  hexImage8.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage8} position={"hex8"}>
                                            <div
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage8.imageSrc}
                                                id ="hex8"
                                                alt="hex8"
                                                onDragStart={event => handleDragStart(event, hexImage8)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                draggable
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>   
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage9.cost === "1" ? "grey" : hexImage9.cost === 2 ? "green" : hexImage9.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage9.cost === 4 ? "purple" :  hexImage9.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage9} position={"hex9"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage9.imageSrc}
                                                id ="hex9"
                                                alt="hex9"
                                                onDragStart={event => handleDragStart(event, hexImage9)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>  
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage10.cost === "1" ? "grey" : hexImage10.cost === 2 ? "green" : hexImage10.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage10.cost === 4 ? "purple" :  hexImage10.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage10} position={"hex10"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage10.imageSrc}
                                                id ="hex10"
                                                alt="hex10"
                                                onDragStart={event => handleDragStart(event, hexImage10)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>  
                            </div>
                            <div 
                                    style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage11.cost === "1" ? "grey" : hexImage11.cost === 2 ? "green" : hexImage11.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage11.cost === 4 ? "purple" :  hexImage11.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage11} position={"hex11"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage11.imageSrc}
                                                id ="hex11"
                                                alt="hex11"
                                                onDragStart={event => handleDragStart(event, hexImage11)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                            </div>    
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage12.cost === "1" ? "grey" : hexImage12.cost === 2 ? "green" : hexImage12.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage12.cost === 4 ? "purple" :  hexImage12.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage12} position={"hex12"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage12.imageSrc}
                                                id ="hex12"
                                                alt="hex12"
                                                onDragStart={event => handleDragStart(event, hexImage12)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage13.cost === "1" ? "grey" : hexImage13.cost === 2 ? "green" : hexImage13.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage13.cost === 4 ? "purple" :  hexImage13.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage13} position={"hex13"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage13.imageSrc}
                                                id ="hex13"
                                                alt="hex13"
                                                onDragStart={event => handleDragStart(event, hexImage13)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage14.cost === "1" ? "grey" : hexImage14.cost === 2 ? "green" : hexImage14.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage14.cost === 4 ? "purple" :  hexImage14.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage14} position={"hex14"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage14.imageSrc}
                                                id ="hex14"
                                                alt="hex14"
                                                onDragStart={event => handleDragStart(event, hexImage14)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>  
                            </div>
                            </div>

                            <div style={{maxWidth:"100%", height: hexTopShift,display:"flex", position:"relative", justifyContent: "space-around" , width: gridWidth, right: hexRelShift, paddingTop: hexTopShift}}>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage15.cost === "1" ? "grey" : hexImage15.cost === 2 ? "green" : hexImage15.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage15.cost === 4 ? "purple" :  hexImage15.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage15} position={"hex15"}>
                                            <div
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage15.imageSrc}
                                                id ="hex15"
                                                alt="hex15"
                                                onDragStart={event => handleDragStart(event, hexImage15)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                draggable
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage16.cost === "1" ? "grey" : hexImage16.cost === 2 ? "green" : hexImage16.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage16.cost === 4 ? "purple" :  hexImage16.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage16} position={"hex16"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage16.imageSrc}
                                                id ="hex16"
                                                alt="hex16"
                                                onDragStart={event => handleDragStart(event, hexImage16)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px", /* equal to border thickness */
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                    <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage17.cost === "1" ? "grey" : hexImage17.cost === 2 ? "green" : hexImage17.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage17.cost === 4 ? "purple" :  hexImage17.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage17} position={"hex17"}>

                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage17.imageSrc}
                                                id ="hex17"
                                                alt="hex17"
                                                onDragStart={event => handleDragStart(event, hexImage17)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px", /* equal to border thickness */
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                                    <div 
                                            style={{  display: "inline-block",
                                                zIndex:"998",
                                                position: "relative",
                                                width: hexagonLWidth,
                                                height: hexagonLHeight, /* width * 0.866 */
                                                background:  `${
                                                    hexImage18.cost === "1" ? "grey" : hexImage18.cost === 2 ? "green" : hexImage18.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage18.cost === 4 ? "purple" :  hexImage18.cost === 5 ? "gold" : "grey"}`,
                                                boxSizing: "border-box",
                                                "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                            }}>
                                                <div>
                                                <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage18} position={"hex18"}>
                                                    <div 
                                                    style={{
                                                            position: "absolute",
                                                            top: "3px", /* eal to border thickness */
                                                            left: "3px", /* equal to border thickness */
                                                            width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                            height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                            "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                            "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                            backgroundColor: "rgba(0, 0, 0, 0.82)",
                                                    }}>
                                                        <img src={hexImage18.imageSrc}
                                                        id ="hex18"
                                                        alt="hex18"
                                                        onDragStart={event => handleDragStart(event, hexImage18)}
                                                        onDrop={handleDrop}
                                                        onDragOver={handleDragOver}
                                                        style={{
                                                            transform: "scale(1.1)",
                                                            position: "relative",
                                                            width: hexagonLHeight,
                                                            height: hexagonLHeight,
                                                            "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                            "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                            backgroundColor: "transparent",
                                                            left: "-8px", /* equal to border thickness */
                                                        }}
                                                        >
                                                        </img>
                                                    </div>
                                                    </StarMenu>
                                                </div>
                                    </div>    
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage19.cost === "1" ? "grey" : hexImage19.cost === 2 ? "green" : hexImage19.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage19.cost === 4 ? "purple" :  hexImage19.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage19} position={"hex19"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage19.imageSrc}
                                                id ="hex19"
                                                alt="hex19"
                                                onDragStart={event => handleDragStart(event, hexImage19)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px", /* equal to border thickness */
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage20.cost === "1" ? "grey" : hexImage20.cost === 2 ? "green" : hexImage20.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage20.cost === 4 ? "purple" :  hexImage20.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage20} position={"hex20"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage20.imageSrc}
                                                id ="hex20"
                                                alt="hex20"
                                                onDragStart={event => handleDragStart(event, hexImage20)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px", /* equal to border thickness */
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage21.cost === "1" ? "grey" : hexImage21.cost === 2 ? "green" : hexImage21.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage21.cost === 4 ? "purple" :  hexImage21.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage21} position={"hex21"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage21.imageSrc}
                                                id ="hex21"
                                                alt="hex21"
                                                onDragStart={event => handleDragStart(event, hexImage21)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px", /* equal to border thickness */
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            </div>


                            <div style={{maxWidth:"100%", height: hexTopShift,display:"flex", position:"relative", justifyContent: "space-around" , width: gridWidth, left: hexRelShift,  paddingTop: hexTopShift}}>
                            <div>
                                <div 
                                    id = "hex22"
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage22.cost === "1" ? "grey" : hexImage22.cost === 2 ? "green" : hexImage22.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage22.cost === 4 ? "purple" :  hexImage22.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage22} position={"hex22"}>
                                            <div
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage22.imageSrc}
                                                id ="hex22"
                                                alt="hex22"
                                                onDragStart={event => handleDragStart(event, hexImage22)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                draggable
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                </div>    
                            </div>
                            <div>
                                <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage23.cost === "1" ? "grey" : hexImage23.cost === 2 ? "green" : hexImage23.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage23.cost === 4 ? "purple" :  hexImage23.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage23} position={"hex23"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage23.imageSrc}
                                                id ="hex23"
                                                alt="hex23"
                                                onDragStart={event => handleDragStart(event, hexImage23)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                </div>    
                            </div>
                            <div>
                                <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage24.cost === "1" ? "grey" : hexImage24.cost === 2 ? "green" : hexImage24.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage24.cost === 4 ? "purple" :  hexImage24.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage24} position={"hex24"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage24.imageSrc}
                                                id ="hex24"
                                                alt="hex24"
                                                onDragStart={event => handleDragStart(event, hexImage24)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                </div>    
                            </div>
                                <div 
                                        style={{  display: "inline-block",
                                            zIndex:"998",
                                            position: "relative",
                                            width: hexagonLWidth,
                                            height: hexagonLHeight, /* width * 0.866 */
                                            background:  `${
                                                hexImage25.cost === "1" ? "grey" : hexImage25.cost === 2 ? "green" : hexImage25.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage25.cost === 4 ? "purple" :  hexImage25.cost === 5 ? "gold" : "grey"}`,
                                            boxSizing: "border-box",
                                            "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                            "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                        }}>
                                            <div>
                                            <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage25} position={"hex25"}>
                                                <div 
                                                style={{
                                                        position: "absolute",
                                                        top: "3px", /* eal to border thickness */
                                                        left: "3px", /* equal to border thickness */
                                                        width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                        height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "rgba(0, 0, 0, 0.82)",
                                                }}>
                                                    <img src={hexImage25.imageSrc}
                                                    id ="hex25"
                                                    alt="hex25"
                                                    onDragStart={event => handleDragStart(event, hexImage25)}
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    style={{
                                                        transform: "scale(1.1)",
                                                        position: "relative",
                                                        width: hexagonLHeight,
                                                        height: hexagonLHeight,
                                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                        backgroundColor: "transparent",
                                                        left: "-8px"
                                                    }}
                                                    >
                                                    </img>
                                                </div>
                                                </StarMenu>
                                            </div>
                                </div>    
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage26.cost === "1" ? "grey" : hexImage26.cost === 2 ? "green" : hexImage26.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage26.cost === 4 ? "purple" :  hexImage26.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage26} position={"hex26"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage26.imageSrc}
                                                id ="hex26"
                                                alt="hex26"
                                                onDragStart={event => handleDragStart(event, hexImage26)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        zIndex:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage27.cost === "1" ? "grey" : hexImage27.cost === 2 ? "green" : hexImage27.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage27.cost === 4 ? "purple" :  hexImage27.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage27} position={"hex27"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage27.imageSrc}
                                                id ="hex27"
                                                alt="hex27"
                                                onDragStart={event => handleDragStart(event, hexImage27)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            <div>
                                   <div 
                                     style={{  display: "inline-block",
                                        Index:"998",
                                        position: "relative",
                                        width: hexagonLWidth,
                                        height: hexagonLHeight, /* width * 0.866 */
                                        background:  `${
                                            hexImage28.cost === "1" ? "grey" : hexImage28.cost === 2 ? "green" : hexImage28.cost === 3 ? "rgba(79, 163, 255, 0.8)" : hexImage28.cost === 4 ? "purple" :  hexImage28.cost === 5 ? "gold" : "grey"}`,
                                        boxSizing: "border-box",
                                        "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                        "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)"
                                    }}>
                                        <div>
                                        <StarMenu matches={matches} handleStar1={handleStar1} handleStar2={handleStar2} handleStar3={handleStar3} champion={hexImage28} position={"hex28"}>
                                            <div 
                                            style={{
                                                    position: "absolute",
                                                    top: "3px", /* eal to border thickness */
                                                    left: "3px", /* equal to border thickness */
                                                    width: hexagonSWidth, /* container height - (border thickness * 2) */
                                                    height: hexagonSHeight, /* container height - (border thickness * 2) */
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                                            }}>
                                                <img src={hexImage28.imageSrc}
                                                id ="hex28"
                                                alt="hex28"
                                                onDragStart={event => handleDragStart(event, hexImage28)}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                                style={{
                                                    transform: "scale(1.1)",
                                                    position: "relative",
                                                    width: hexagonLHeight,
                                                    height: hexagonLHeight,
                                                    "WebkitClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    "MozClipPath": "polygon(0% 25%, 50% 0%, 100% 25%, 100.00% 75.00%, 50% 100.00%, 0% 75%)",
                                                    backgroundColor: "transparent",
                                                    left: "-8px"
                                                }}
                                                >
                                                </img>
                                            </div>
                                            </StarMenu>
                                        </div>
                                    </div>    
                            </div>
                            </div>


                            </div>

                            </Box>
                        </Grid>
                    </Grid>
                    ) : <></> }

                    <Grid item style={{height: hideBoard.heightGraph, maxWidth: graphWidth }}>
                        <Box sx={{ border: 0, borderColor: 'grey.200', height: '100%'}} >
                            <div style = {{ zIndex:"1", backgroundColor: "#121212", height: '100%' }}>
                            <Suspense fallback={renderLoader()}>
                                <link
                                    rel="stylesheet"
                                    type="text/css"
                                    href="https://fonts.googleapis.com/css?family=Outfit:300,400,500"
                                /> 
                                <Graph
                                    graph={graph}
                                    getEdges={getEdges}
                                    getNodes={getNodes}
                                    getNetwork={getNetwork}
                                    options={options}
                                />
                                </Suspense>
                            </div>
                        </Box>
                    </Grid>
                    { !matches && (
                    <Grid item style={{height: "100%", width: sidebarWidth, backgroundColor: "#121212"}}>
                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", paddingLeft: "10px" }} >
                            <Typography variant="h6" align="left" style={{ color: "white", borderBottom: '4px solid #ffffff' }}>
                                Team Comps
                            </Typography>
                            <Box sx={{paddingRight: "20px"}}> 
                            <Select
                                value={teamCompSource}
                                onChange={handleSourceChange}
                                style={{
                                backgroundColor: "white",
                                color: "black",
                                maxHeight: 36,
                                width: 100,
                                }}
                                MenuProps={{
                                PaperProps: {
                                    style: {
                                    backgroundColor: "white",
                                    color: "black",
                                    },
                                },
                                MenuListProps: {
                                    style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    },
                                },
                                }}
                            >
                                    <MenuItem value="meta">META</MenuItem>
                                    <MenuItem  disabled={!user} value="user">USER</MenuItem>
                                </Select>
                            </Box>
                        </div>
                        <div style={{ overflowY: 'scroll', scrollbarWidth: 'thin', scrollbarColor: 'rgba(255, 255, 255, 0.1) #121212', height:"calc(100% - 32px)" }}> 
                        <Grid container spacing={0} style={{ padding: 0, width: '100%', height:'100%' }}>
                        {loadingRecommendations && 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap', marginTop: '20px', marginBottom: '20px', width: '100%', boxSizing: 'border-box' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <WhiteCircularProgress style={{marginBottom: '20px', marginTop: "25px"}}/>
                            <WhiteTextTypography style={{marginTop: "10px"}}>Loading...</WhiteTextTypography>
                        </div>
                    </div>
                    }
                    {sortedTeams && !loadingRecommendations && (
                    <div style={{ display: 'flex', width: '100%', maxWidth:'350px', height:'100%' }}>
                        <div style={{ width:"350px", height:'100%'}}>
                            <AutoSizer style={{width:'350px', height:"100%", overflowX:'hidden'}}>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    itemCount={sortedTeams.length}
                                    itemSize={160} // replace with your desired row height
                                    itemData={sortedTeams}
                                    width={width}
                                >
                                {TeamRow}
                                </List>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                    )}
                </Grid>
            </div>
        </Grid>)}
    </Grid>
</div>

</div>
    );
}