import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { AuthContext } from './auth/AuthContext';

const pagesLoggedOut = new Map([
  ['TFT Team Builder', 'team-builder'],
  ['TFT Team Comps', 'team-comps'],
  ['Log In', 'login']
])

const pagesLoggedIn = new Map([
  ['TFT Team Builder', 'team-builder'],
  ['TFT Team Comps', 'team-comps'],
  ['Your Profile', 'account']
])


function ResponsiveAppBar() {
  const matches = useMediaQuery(`(max-width:${600}px)`);
  const user = useContext(AuthContext);

  const pagesToUse = user ? pagesLoggedIn : pagesLoggedOut;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const history = useHistory();
  const routeChange = (input) => {
    handleCloseNavMenu();
    history.push(input);
  }

  return (
    <Box sx={{ flexGrow: 1, paddingLeft:'20px', paddingRight:'20px', height:"56px", position:"sticky", top: 0, zIndex:999, display:"flex"}}  style={{background: '#121212'}}>
<AppBar position='static' elevation={0} style={{background: '#121212',margin:"0"}} sx={{paddingLeft:"0", paddingRight:"0", zIndex: 1}}>
        <Toolbar style={{margin:"0", padding:"0px"}} sx={{paddingLeft:"0", paddingRight:"0"}}>
          <Box display="flex" alignItems="flex-end">
            <Typography
              variant="h6"
              noWrap
              component="a"
              href='https://graphtft.com/'
              onClick={() => routeChange("/")}
              sx={{
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                fontFamily:"Outfit",
                color:"white"
              }}
            >
              GraphTFT
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontWeight: 500,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                fontFamily:"Outfit",
                paddingBottom: '2px',
                paddingLeft: "4px",
                fontSize: "12px",
                color: "white"
              }}
            >
              Set 10
            </Typography>
          </Box>

          {!matches && (
            <Box sx={{ flexGrow: 1, height: "56px", display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'flex-end' }}>
              {Array.from(pagesToUse, ([key, value]) => (
                <Button
                  key={key}
                  onClick={() => routeChange(value)}
                  sx={{ 
                    my: 2, 
                    color: 'white', 
                    display: 'block',
                    textTransform: 'none', 
                    fontFamily:"Outfit", 
                    fontSize: "18px",
                    marginRight: '0px', // Added margin to right side
                    marginLeft: '20px'  // Added margin to left side
                  }}
                >
                  {key}
                </Button>
              ))}
            </Box>
          )}
          
          {matches && (
            <Box sx={{ flexGrow: 1, height: "56px", display: "flex", alignItems:"center", justifyContent:"flex-end", color:"white" ,                 padding:"0px"
          }}>
              <IconButton
                size="large"
                aria-label="tft-menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="white"
                padding="0px"
              >
                <MenuIcon   sx={{color:"white", padding:"0px"}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                 vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                }}
              >
              {Array.from(pagesToUse, ([key, value]) => (
                <MenuItem key={key} onClick={() => routeChange(value)} sx={{ fontFamily: "Outfit", fontWeight: 700, letterSpacing: '.1rem', color: 'inherit', textDecoration: 'none', fontSize: "18px" }}>
                  {key}
                </MenuItem>
              ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default ResponsiveAppBar;
