import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const WhiteTextField = styled(TextField)({
    fontFamily: 'Pouty, sans-serif', // Replace 'Pouty' with your desired font family
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  });

  export default WhiteTextField
