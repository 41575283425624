import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import WhiteTextTypography from '../utils/WhiteTextTypography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const CssTextField = styled(TextField)({
  fontFamily: 'Pouty, sans-serif', 
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});


const ForgotPassword = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const matches = useMediaQuery(`(max-width:${600}px)`);


    var explorationHeight = 0
    if (!matches) {
        explorationHeight = "calc(100dvh - 112px)"
    }
    else {
        explorationHeight = "calc(100dvh - 120px)"
    }
    const onResetPassword = (e) => {
      e.preventDefault();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSnackbarOpen(true);
          setErrors([]);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
    
            // Check for specific error messages and set custom error messages
            if (errorMessage.includes('auth/invalid-email')) {
              setErrors(['Invalid email.']);
            } else if (errorMessage.includes('auth/user-not-found')) {
              setSnackbarOpen(true); // Show snackbar even when user is not found
              setErrors([]);
            } else {
              setErrors(["Something went wrong"]);
            }
          });
    };
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={explorationHeight}
    >
      <Box>
      <WhiteTextTypography variant="h4" align="center" gutterBottom mb={2}>
          Forgotten your password?
        </WhiteTextTypography>   
        <Box maxWidth="400px">
        <WhiteTextTypography  align="center" gutterBottom mb={3}>
          Enter your email to reset your password.
        </WhiteTextTypography>
        </Box>   
        <form>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box mb={2} width="300px">
              <CssTextField
                type="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email address"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'Outfit',
                    color: 'white',
                    '&.Mui-focused': {
                      color: 'white',
                    },
                  },
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
            {errors.length > 0 && (
                errors.map((error, index) => (
                  <Box key={index} mb={1} width="300px">
                    <Typography variant="body1" align="center" color="error">
                        {error}
                    </Typography>
                  </Box>
                ))
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={onResetPassword}
            >
              Send reset link
            </Button>
          </Box>
        </form>
        <WhiteTextTypography variant="body1" align="center" mt={2}>
          Remembered your password?{' '}
            <NavLink to="/login">
              Log In
            </NavLink>
          </WhiteTextTypography>
          <WhiteTextTypography variant="body1" align="center" mt={1}>
          No account yet?{' '}
            <NavLink to="/signup">
              Sign Up
            </NavLink>
          </WhiteTextTypography>
      </Box>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert 
          onClose={handleSnackbarClose} 
          severity="success" 
          elevation={6} 
          variant="filled"
        >
          Password reset link sent
        </MuiAlert>
      </Snackbar>

    </Box>
  );
};

export default ForgotPassword;
