import React from 'react';

// List of new champions with their two-letter keys
export const numberChampionMap = [
  ['aa', 'ahri'],
  ['ab', 'akalikda'],
  ['ac', 'akalitruedamage'],
  ['ad', 'aumu'],
  ['ae', 'annie'],
  ['af', 'aphelios'],
  ['ag', 'bard'],
  ['ah', 'blitzcrank'],
  ['ai', 'caitlyn'],
  ['aj', 'corki'],
  ['ak', 'ekko'],
  ['al', 'evelynn'],
  ['am', 'ezreal'],
  ['an', 'garen'],
  ['ao', 'gnar'],
  ['ap', 'gragas'],
  ['aq', 'illaoi'],
  ['ar', 'jax'],
  ['as', 'jhin'],
  ['at', 'jinx'],
  ['au', 'kaisa'],
  ['av', 'karthus'],
  ['aw', 'katarina'],
  ['ax', 'kayle'],
  ['ay', 'kayn'],
  ['az', 'kennen'],
  ['ba', 'ksante'],
  ['bb', 'lillia'],
  ['bc', 'lucian'],
  ['bd', 'lulu'],
  ['be', 'lux'],
  ['bf', 'miss Fortune'],
  ['bg', 'mordekaiser'],
  ['bh', 'nami'],
  ['bi', 'neeko'],
  ['bj', 'olaf'],
  ['bk', 'pantheon'],
  ['bl', 'poppy'],
  ['bm', 'wiyana'],
  ['bn', 'riven'],
  ['bo', 'samira'],
  ['bp', 'senna'],
  ['bq', 'seraphine'],
  ['br', 'sett'],
  ['bs', 'sona'],
  ['bt', 'tahmkench'],
  ['bu', 'taric'],
  ['bv', 'thresh'],
  ['bw', 'twistedfate'],
  ['bx', 'twitch'],
  ['by', 'urgot'],
  ['bz', 'vex'],
  ['ca', 'vi'],
  ['cb', 'viego'],
  ['cc', 'yasuo'],
  ['cd', 'yone'],
  ['ce', 'yorick'],
  ['cf', 'zac'],
  ['cg', 'zed'],
  ['ch', 'ziggs'],

];

export const letterNumberMap = new Map([
  ['a', '1'],
  ['b', '2'],
  ['c', '3'],
  ['d', '4'],
  ['e', '5'],
  ['f', '6'],
  ['g', '7'],
  ['h', '8'],
  ['i', '9'],
  ['j', '10'],
  ['k', '11'],
  ['l', '12'],
  ['m', '13'],
  ['n', '14'],
  ['o', '15'],
  ['p', '16'],
  ['q', '17'],
  ['r', '18'],
  ['s', '19'],
  ['t', '20'],
  ['u', '21'],
  ['v', '22'],
  ['w', '23'],
  ['x', '24'],
  ['y', '25'],
  ['z', '26'],
]);

export default function ChampionMap() {
  return <></>;
}