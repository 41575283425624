import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile, getAuth } from 'firebase/auth';
import { auth } from '../firebase';
import { Box, Button, Typography } from '@mui/material';
import WhiteTextTypography from '../utils/WhiteTextTypography';
import WhiteTextField from '../utils/WhiteTextField';
import { useMediaQuery } from '@mui/material';
import { gql, useLazyQuery, useApolloClient, useQuery } from "@apollo/client";

const ADD_USER = gql`
  mutation($id: String!, $username: String!) {
    createUsers(input: [
      {
        id: $id
        username: $username
      }
    ]) {
      users {
        id
        username
      }
    }
  }
`;

const SignUp = () => {
    const history = useHistory();
    const client = useApolloClient();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [accountCreated, setAccountCreated] = useState(false);
    
    const matches = useMediaQuery(`(max-width:${600}px)`);

    var explorationHeight = 0
    if (!matches) {
        explorationHeight = "calc(100dvh - 112px)"
    }
    else {
        explorationHeight = "calc(100dvh - 120px)"
    }

    async function addUser(id, username) {
      const result = await client.mutate({
        mutation: ADD_USER,
        variables: { id: id, username: username },
      });
      console.log(result.data)
      return null;
    }
  
    const onSubmit = async (e) => {
      e.preventDefault();
  
      let errorList = [];

      if (email !== confirmEmail) {
        errorList.push("Emails do not match");
      }
      if (password !== confirmPassword) {
        errorList.push("Passwords do not match");
      }

      if (errorList.length > 0) {
        setErrors(errorList);
        return;
      }
  
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setErrors([]);
          setAccountCreated(true);
          addUser(email, username);
          const auth = getAuth();
          updateProfile(auth.currentUser, {
            displayName: username
          }).then(() => {
            // success!
          }).catch((error) => {
            console.log("Username failed to updated")
          });
        });
      } catch (error) {
        if (error.code === "auth/weak-password") {
          setErrors(["Password must be atleast six characters"]);
        } else {
          setErrors(["Something went wrong"]);
        }
      }
    };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={explorationHeight}
      width="100%"
    >
      {!accountCreated ? (
      <Box>
        <WhiteTextTypography variant="h4" align="center" gutterBottom mb={2}>
          Sign Up!
        </WhiteTextTypography>   
        <Box maxWidth="400px" margin="auto" paddingLeft="30px" paddingRight="30px">
        <WhiteTextTypography  align="center" gutterBottom mb={3}>
          Create an account to save your favourite teams and get access to more features.
        </WhiteTextTypography>
        </Box>     

        <form onSubmit={onSubmit}>
          <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} width="300px">
              <WhiteTextField
                type="username"
                label="Username"
                variant='outlined'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="Username"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white', // set the initial color of the label
                      '&.Mui-focused': {
                        color: 'white', // set the color of the label when the TextField is focused
                      },
                    },
                  }}
                sx={{
                    width: "100%",
                }} 
              />
            </Box>            
            <Box mb={2} width="300px">
              <WhiteTextField
                type="email"
                label="Email address"
                variant='outlined'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email address"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white', // set the initial color of the label
                      '&.Mui-focused': {
                        color: 'white', // set the color of the label when the TextField is focused
                      },
                    },
                  }}
                sx={{
                    width: "100%",
                }} 
              />
            </Box>
            <Box mb={2} width="300px">
                <WhiteTextField
                    type="email"
                    label="Confirm email address"
                    variant='outlined'
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                    placeholder="Confirm email address"
                    inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                    InputLabelProps={{
                        sx: {
                        fontFamily: 'Outfit',
                        color: 'white', // set the initial color of the label
                        '&.Mui-focused': {
                            color: 'white', // set the color of the label when the TextField is focused
                        },
                        },
                    }}
                    sx={{
                        width: "100%",
                    }} 
                />
            </Box>
            <Box mb={2} width="300px">
              <WhiteTextField
                type="password"
                label="Create password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white', // set the initial color of the label
                      '&.Mui-focused': {
                        color: 'white', // set the color of the label when the TextField is focused
                      },
                    },
                  }}
                sx={{
                    width: "100%",
                }} 
              />
            </Box>
            <Box mb={2} width="300px">
              <WhiteTextField
                type="password"
                label="Confirm password"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm Password"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                    sx: {
                      fontFamily: 'Outfit',
                      color: 'white', // set the initial color of the label
                      '&.Mui-focused': {
                        color: 'white', // set the color of the label when the TextField is focused
                      },
                    },
                  }}
                sx={{
                    width: "100%",
                }} 
              />
            </Box>
            {errors.length > 0 && (
                errors.map((error, index) => (
                  <Box key={index} mb={2} width="300px">
                    <Typography variant="body1" align="center" color="error">
                        {error}
                    </Typography>
                  </Box>
                ))
            )}
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: '#3f51b5', color: '#fff' }}
            >
              Sign up
            </Button>
          </Box>
        </form>
        <WhiteTextTypography variant="body1" align="center" mt={2}>
          Already have an account?{' '}
          <NavLink to="/login" style={{ color: '#3f51b5' }}>
            Sign in
          </NavLink>
        </WhiteTextTypography>
      </Box>
      ) : (
           <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <WhiteTextTypography variant="h4" align="center" gutterBottom mb={2}>
                Account Created!
            </WhiteTextTypography>
            <Box maxWidth="400px">
                <WhiteTextTypography align="center" gutterBottom mb={3}>
                    Verification email sent
                </WhiteTextTypography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { 
                        window.location.href = '/login';
                    }}
                >
                    Log In
                </Button>
            </Box>
        </Box>     
      )}

    </Box>
  );
};

export default SignUp;