import React, { useContext, useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import WhiteTextTypography from '../utils/WhiteTextTypography';
import { AuthContext } from './AuthContext';
import {db} from '../firebase'
import { getDocs, collection } from "firebase/firestore"
import { gql, useApolloClient } from "@apollo/client";

const CHAMPION_QUERY = gql`
  query explore($name: String) {
    champions(where: { id: $name }) {
      id
      name
      imageSrc
      health1
      mana1
      armor1
      mr1
      dps1
      atkSpd1
      range1
      damage1
      health2
      mana2
      armor2
      mr2
      dps2
      atkSpd2
      range2
      damage2
      health3
      mana3
      armor3
      mr3
      dps3
      atkSpd3
      range3
      damage3
      synergies {
        id
        name
        imageSrc
        counts
      }
      cost
      tier
      recommendedItemsConnection {
        edges {
          team
          node {
            id
            name
            imageSrc
          }
        }
      }
    }
  }
`;

function CompRow(props) {

  const history = useHistory();

  const pushToTeamBuilder = (team) => {
    history.push('/team-builder', { teamId: team.id, teamData: team.membersConnection });
  };

  return (
    <div>
    <Box sx={{ padding: '10px 20px', backgroundColor:'#121212'}}>
      <WhiteTextTypography sx={{fontFamily:"Outfit",color: 'white', fontSize:"20px"}} >Team!</WhiteTextTypography>
      <Box sx={{ maxWidth: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", margin: "0 auto", flexWrap: "wrap" }}>
        {team.map((member) => (
          <Box style={{ marginInline: "auto", display: "grid" }} key={`box-${props.team.id}-${member.node.id}-${Math.random()}`}>
            <Tooltip title={member.node.name} key={`tooltip${props.team.id}${member.node.id}${member.position}`} arrow>
              <img
                key={`${props.team.id}${member.node.id}${member.position}`}
                src={member.node.imageSrc}
                alt={member.node.name}
                style={{
                  width: "40px",
                  height: "40px",
                  margin: "2px",
                  border: `${
                    member.node.cost === 1
                      ? "2px solid grey"
                      : member.node.cost === 2
                      ? "2px solid green"
                      : member.node.cost === 3
                      ? "2px solid rgba(79, 163, 255, 0.8)"
                      : member.node.cost === 4
                      ? "2px solid purple"
                      : member.node.cost === 5
                      ? "2px solid gold"
                      : "2px solid black"
                  }`,
                }}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop:"10px" }}>
        <Button variant="contained" endIcon={<AddIcon />} onClick={() => pushToTeamBuilder(props.team)}>
          Open in Team Builder
        </Button>
      </Box>
    </Box>
    <Divider style={{ backgroundColor: 'white', margin: '0 auto', width: '400px', marginTop: '10px', marginBlock: '10px' }} />
  </div>
  
  )
}

const Account = () => {
  const meta = {
    title: 'TFT Set 10 Saved Teams (Your Best Champions and Best Teams) - Teamfight Tactics - GraphTFT',
    description: 'Saved list of best team comps and best champions for TFT Set 10 Remix Rumble',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'tft,meta,team comps,team builder,set 10, tft mid set,tier list,tft team comps,tft champion guide,tft team comp,tft planner,tft meta'
        }
    }
  }
  const [data, setData] = useState(null);
  
  const client = useApolloClient();

  const history = useHistory();
  const user = useContext(AuthContext);

  const [teams, setTeams] = useState(null);

  async function fetchChampion(championId) {
    const rels = await client.query({
        query: EXPLORATION_QUERY,
        variables: { name: championId },
    });
    if (rels.data && rels.data.champions) {
        console.log(rels.data.champions[0]);
    }        
  };

  useEffect(() => {
    const fetchData = async () => {
      const colRef = collection(db, "user-teams");

      try {
        const querySnapshot = await getDocs(colRef);
        const newTeams = [];
        querySnapshot.forEach((doc) => {
          newTeams.push(doc.data());
        });
        setTeams(newTeams);
      } catch (error) {
        console.error('Error getting documents: ', error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        history.push('/');
        console.log('Signed out successfully');
      })
      .catch((error) => {
        console.error('Error during sign-out:', error);
      });
  };

  if (!user) {
    history.push('/login');
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      // justifyContent="center"
    >
      <Box>
        <WhiteTextTypography variant="h4" align="center" gutterBottom>
          Your Saved Teams
        </WhiteTextTypography>
      </Box>

      <Box>
      </Box>

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleLogout}>
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

export default Account;
