import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useHistory } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import WhiteTextTypography from '../utils/WhiteTextTypography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const CssTextField = styled(TextField)({
  fontFamily: 'Pouty, sans-serif', // Replace 'Pouty' with your desired font family
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const matches = useMediaQuery(`(max-width:${600}px)`);
  const [errors, setErrors] = useState([]);

  var explorationHeight = 0
  if (!matches) {
      explorationHeight = "calc(100dvh - 112px)"
  }
  else {
      explorationHeight = "calc(100dvh - 120px)"
  }

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        history.push('/account');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
            // Check for specific error messages and set custom error messages
            if (errorMessage.includes('auth')) {
              setErrors(['Email or password is incorrect.']);
            } else {
              setErrors([errorMessage]);
            }
          });
        };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={explorationHeight}
    >
      <Box>
        <WhiteTextTypography variant="h4" align="center" gutterBottom>
          Log In
        </WhiteTextTypography>
        <form>
          <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={2} width="300px">
              <CssTextField
                type="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email address"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'Outfit',
                    color: 'white', // set the initial color of the label
                    '&.Mui-focused': {
                      color: 'white', // set the color of the label when the TextField is focused
                    },
                  },
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
            <Box mb={2} width="300px">
              <CssTextField
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
                inputProps={{ style: { color: 'white', fontFamily: 'Outfit' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'Outfit',
                    color: 'white', // set the initial color of the label
                    '&.Mui-focused': {
                      color: 'white', // set the color of the label when the TextField is focused
                    },
                  },
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
            {errors.length > 0 && (
                errors.map((error, index) => (
                  <Box key={index} mb={2} width="300px">
                    <Typography variant="body1" align="center" color="error">
                        {error}
                    </Typography>
                  </Box>
                ))
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={onLogin}
            >
              Log In
            </Button>
            <Box mt={2}>
            <WhiteTextTypography variant="body1" align="center">

              <NavLink to="/forgot-password" style={{ color: '#3f51b5' }}>
                Forgot password?
              </NavLink>
              </WhiteTextTypography>

            </Box>
          </Box>
        </form>
        <WhiteTextTypography variant="body1" align="center" mt={2}>
          No account yet?{' '}
            <NavLink to="/signup" style={{ color: '#3f51b5' }}>
              Sign Up
            </NavLink>
          </WhiteTextTypography>
      </Box>
    </Box>
  );
};

export default Login;
