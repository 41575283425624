import * as React from 'react'
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { styled } from '@mui/system';  // `makeStyles` is replaced with `styled` or `sx`
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import set10background from './../assets/set10background.webp';
import DocumentMeta from 'react-document-meta';
import { useHistory } from 'react-router-dom';


function Welcome() {

  const meta = {
    title: 'TFT Team Comps and Meta Builds - GraphTFT',
    description: 'Find the best TFT comps and item builds using the GraphTFT team builder tool.',
    canonical: 'http://graphtft.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'tft,meta,stats,team builder,set 10, tft mid set,teamfight tactics,tft builder,tft team planner,tft comps,tft team comps,tft item guides'
        }
    }
  }

  const StyledDividerWide = styled(Divider)({
    background: "white",
    m: 10,
    width: "90vw"
});

  const StyledDividerNarrow = styled(Divider)({
    background: "white",
    m: 10,
    width: "60vw"
  });

  const WhiteTextTypography = styled(Typography)({
    color: "#FFFFFF"
  });

  const history = useHistory();

  const routeChange = (input) => { 
    history.push(input);
  }

return (
  <div>
    <DocumentMeta {...meta} />
  <div
    style={{
        overflowX: "auto",
        textAlign:"center",
        alignItems:"center",
        justifyContent: "center",
        justifyItems:"center",
        width: "100%",
        height:"100%",
        boxSizing: "border-box" // <--- this line
      }}
      >


{true && (
  <Box sx={{ position: 'relative', margin: '10px' }}>
    <Box sx={{ m: 2 }}>
      <img src={set10background} style={{ maxWidth: '100%', height: '810px', width:'auto', objectFit: 'cover', borderRadius: '8px', opacity:'0.3' }} alt="tft team builder" />
    </Box>
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', whiteSpace: 'wrap' }}>
      <WhiteTextTypography>
        <Box sx={{ letterSpacing: 3, fontWeight:"bold", m:5, fontSize:30 }}>
          The most in depth Teamfight Tactics team builder
        </Box>
        <Box sx={{ letterSpacing: 3, fontWeight:"light", m:2, mb:5 }}>
          Discover the best TFT team comps
        </Box>       
      </WhiteTextTypography>
      <Box sx={{m:5}}>
            <Button
        variant="contained"
        onClick={() => routeChange("team-builder")}
        size="large"
      >
        OPEN TEAM BUILDER
      </Button>
      </Box>
    </Box>
  </Box> 
)}


<div
  style={{
    maxWidth: "800px", // Adjust the value to your desired width
    margin: "0 auto", // Center the content horizontally
    padding: "2em 1em",
    overflowX: "hidden",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    width: "100%",
    height: "100%",
    boxSizing: "border-box" // <--- this line
  }}
>


      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerWide />
      </div>

      <Box sx= {{m:5}}>
      <WhiteTextTypography >
            <Box sx={{ letterSpacing: 3, fontWeight:"bold", fontSize:30 }}>
              What is GraphTFT?
            </Box>          
          </WhiteTextTypography>
        </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerNarrow />
      </div>

      <Box sx={{ m: 5, display: 'flex', alignItems: 'center' }}>
      <WhiteTextTypography>
        <Box sx={{ letterSpacing: 3, fontWeight: 'bold', fontSize: 30 }}>
          TFT Team Builder
        </Box>
        <Box sx={{ letterSpacing: 2, fontWeight: 'light', m: 1 }}>
          GraphTFT is a TFT team builder and TFT team planner tool. Unlike other team builders, GraphTFT uses networks to display champion and item recommendations, as well as tiers, stats and meta information. Use this team planner to get the stats of your entire team comp!
        </Box>
        <Box sx={{ letterSpacing: 2, fontWeight: 'light', m: 2 }}>
          GraphTFT uses the latest TFT version, Set 10: Remix Rumble!
        </Box>
      </WhiteTextTypography>
    </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerNarrow />
      </div>

      <Box sx= {{m:5}}>
      <WhiteTextTypography >
            <Box sx={{ letterSpacing: 3, fontWeight:"bold", fontSize:30 }}>
              TFT Team Comps
            </Box>
            <Box
              sx={{ letterSpacing: 2, fontWeight:"light", m:1 }}
            >
              When a champion is added to the team builder, the TFT meta comps get automatically filtered, recommending you the best comps to play for any team. 
            </Box>         
          </WhiteTextTypography>
        </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerNarrow />
      </div>

      <Box sx= {{m:5}}>
      <WhiteTextTypography >
            <Box sx={{ letterSpacing: 3, fontWeight:"bold", fontSize:30 }}>
              TFT Team Planner and Board Builder
            </Box>
            <Box
              sx={{ letterSpacing: 2, fontWeight:"light", m:1 }}
            >
              Starting with one cost champions, see which champions to choose next based on the TFT meta comps. 
            </Box>         
          </WhiteTextTypography>
        </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerNarrow />
      </div>


      <Box sx= {{m:5}}>
      <WhiteTextTypography >
            <Box sx={{ letterSpacing: 3, fontWeight:"bold", fontSize:30 }}>
              Getting Started
            </Box>
            <Box
              sx={{ letterSpacing: 2, fontWeight:"light", m:1 }}
            >
                Start by opening the team builder page. Press the add button to add your champions to the tft board builder. Your team stats will display in the panel. You can then click on any champion, item or synergy on the graph to view detailed information such as the champion cost, recommendation and tier. The right hand side will show TFT comps that use those champs.
            </Box>
            <Box
              sx={{ letterSpacing: 2, fontWeight:"light", m:2 }}
            >
              Support for multiple languages including Korean and Japanese is coming soon!
            </Box>               
          </WhiteTextTypography>
        </Box>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDividerWide />
      </div>

      <WhiteTextTypography>
  <Box sx={{ letterSpacing: 1, fontWeight: "light", m: "50px 50px 10px", color: "white" }}>
    Let me know what you think on Twitter!
  </Box>
</WhiteTextTypography>
  <Button href="https://twitter.com/graphtft" component={Link} sx={{ marginTop: "-10px", color: "white" }}>
    @graphtft
  </Button>
  </div>
  <div
    style={{
        padding: "2em 1em",
        overflowX: "hidden",
        textAlign:"center",
        alignItems:"center",
        justifyContent: "center",
        justifyItems:"center",
        width: "100%",
        height:"100%",
        boxSizing: "border-box" // <--- this line
      }}
      >

<WhiteTextTypography>
        <Box
              sx={{ letterSpacing: 0.5, fontWeight:"light", m:1, color:"grey", fontSize:10}}
            >
              GraphTFT isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.
            </Box>  
            </WhiteTextTypography>
  </div>
      </div>
      </div>
)
};

export default Welcome;
