import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import ReactGA from "react-ga4";
import './assets/css/fonts.css';

ReactGA.initialize("G-85LBCSSC6P");
function RenderApp() {

  const client = new ApolloClient({
    uri: "./.netlify/functions/graphql",
    cache: new InMemoryCache(),
  });

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
          <App />
      </ApolloProvider>
    </React.StrictMode>
  );
  
}
ReactDOM.render(<RenderApp />, document.getElementById('root'));